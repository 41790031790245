import React from "react";
import { Button } from "@material-ui/core";
import { withFirebase } from "../Firebase";

const MainPage = props => {
  const handleLogout = e => {
    props.firebase.doSignOut();
  };
  return (
    <div>
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
};

export default withFirebase(MainPage);
