import {
  Box,
  Card,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { grey, lightBlue } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import { Search, Clear, DeleteOutline, EditOutlined } from "@material-ui/icons";
import { fetchAllUsers } from "../../actions";
import { withFirebase } from "../Firebase";
import { connect } from "react-redux";
import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import { withDevice } from "../web/device";
import DeleteUser from "./delete_user";
import UpdateUser from "./update_user";

const UsersTable = ({ list, setUser, setDeleteOpen, setUpdateOpen }) => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([...list]);

  const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: lightBlue[100] };
    } else {
      return { backgroundColor: lightBlue[300] };
    }
  };
  const handleChange = (e) => {
    setSearch((old) => e.target.value);
  };

  const editClick = (user) => {
    setUser(user);
    setUpdateOpen(true);
  };

  const deleteClick = (user) => {
    setUser(user);
    setDeleteOpen(true);
  };

  

  useEffect(() => {
    const filterUsers = () => {
      if (search === "") {
        setUsers([...list]);
      } else {
        setUsers(
          [...list].filter((user) => {
            if (
              user.name &&
              user.email &&
              user.contact &&
              user.organization_name &&
              user.address
            ) {
              return (
                user.name.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.contact.toLowerCase().includes(search.toLowerCase()) ||
                user.organization_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                user.address.toLowerCase().includes(search.toLowerCase())
              );
            } else if (
              user.name &&
              user.email &&
              user.contact &&
              user.organization_name
            ) {
              return (
                user.name.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.contact.toLowerCase().includes(search.toLowerCase()) ||
                user.organization_name
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            } else if (user.name && user.email && user.contact) {
              return (
                user.name.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.contact.toLowerCase().includes(search.toLowerCase())
              );
            } else if (user.name && user.email) {
              return (
                user.name.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase())
              );
            } else {
              return user.email.toLowerCase().includes(search.toLowerCase());
            }
          })
        );
      }
    };

    
    filterUsers();
  }, [search, list]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <Box
        display="flex"
        style={{ width: "100%", height: "100%", padding: 10 }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Typography>User List ( {list.length} )</Typography>

          <Box display="flex" style={{ paddingRight: 20 }}>
            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box display="flex" flexGrow="1">
          <div style={{ width: "100%", height: "100%" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={users.length}
                  rowGetter={({ index }) => users[index]}
                  rowStyle={({ index }) => rowStyle(index)}
                  // headerStyle={headerStyle}
                >
                  <Column
                    width={60}
                    flexGrow={1}
                    label="NAME"
                    dataKey="name"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />
                  <Column
                    width={60}
                    flexGrow={1}
                    label="EMAIL"
                    dataKey="email"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Contact"
                    dataKey="contact"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="ORG"
                    dataKey="organization_name"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="ADDRESS"
                    dataKey="address"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    headerStyle={{ textAlign: "right", marginRight: 20 }}
                    width={60}
                    flexGrow={1}
                    label="Action"
                    dataKey="action"
                    cellRenderer={({ rowData }) => (
                      <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="Edit">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => editClick(rowData)}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => deleteClick(rowData)}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Box>
    </Card>
  );
};

const UsersPage = ({ firebase, fetchAllUsers, users, deviceParam }) => {
  const { setTitle, setUser } = deviceParam;
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  useEffect(() => {
    setTitle("User List");
    if (users.length === 0) {
      fetchAllUsers(firebase);
    }
  }, []);
  return (
    <Box
      display="flex"
      style={{ padding: 10, width: "100%", backgroundColor: grey[500] }}
    >
      <UsersTable
        list={users}
        setUser={setUser}
        setDeleteOpen={setDeleteOpen}
        setUpdateOpen={setUpdateOpen}
      />
      <DeleteUser open={deleteOpen} setOpen={setDeleteOpen} />
      <UpdateUser open={updateOpen} setOpen={setUpdateOpen} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllUsers: (firebase) => dispatch(fetchAllUsers(firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(UsersPage)));
