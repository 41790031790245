import React, { useState } from "react";
import axios from "axios";
import { COMMAND_URL } from "../../../utils/url";

import {
  Slide,
  Dialog,
  Card,
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";

import { Power, PowerOff, ViewComfy } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { withDevice } from "../device";
import MySnackBar from "./my_snackbar";

const myList = [
  { name: "Power ON", icon: Power },
  { name: "Power OFF", icon: PowerOff },
  { name: "Tax Token", icon: ViewComfy },
  { name: "Set Boundary", icon: ViewComfy },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Settings = ({ open, setOpen, deviceParam }) => {
  const { device, setDevice } = deviceParam;
  const [visible, setVisible] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [snacState, setSnacState] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const itemClick = (index) => {
    console.log(index);

    if (index === 0 || index === 1) {
      const command = {
        device_id: device.id,
        command_type: "S20",
        power: index === 0 ? "ON" : "OFF",
        center_number: device.center_number,
      };

      setVisible(true);

      axios
        .post(COMMAND_URL, command)
        .then((response) => {
          setVisible(false);
          setSnacState({
            open: true,
            message:
              "Your command is Executed Successfully. Command will be Executed within 30 seconds",
          });
        })

        .catch((err) => {
          setVisible(false);
          console.log(err);
        });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Card style={{ padding: 20, width: 500, height: 400 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">
                Settings of {device && device.registration_number}
              </Typography>
            </Box>

            {visible && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            <List style={{ width: "100%", overflow: "hiddeen" }}>
              {myList.map((item, index) => (
                <ListItem
                  divider
                  key={index}
                  style={
                    hoverIndex === index
                      ? { cursor: "pointer", backgroundColor: grey[200] }
                      : { cursor: "pointer" }
                  }
                  onMouseEnter={() => setHoverIndex((ii) => index)}
                  onClick={() => itemClick(index)}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="button">{item.name}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" onClick={() => setOpen(false)}>
              Hide
            </Button>
          </Box>
        </Box>
      </Card>

      <MySnackBar snacState={snacState} setSnacState={setSnacState} />
    </Dialog>
  );
};

export default withDevice(Settings);
