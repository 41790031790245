/*import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import { Grid, TextField, Button } from "@material-ui/core";

import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";

const SignUpLink = () => (
  <p style={{ margin: 10 }}>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const INITIAL_STATE = {
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class SignupBaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    console.log("HHHHHHH", props, "On Signup");
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, passwordOne } = this.state;

    this.props.firebase
      .createUserWithEmailAndPassword(email, passwordOne)
      .then(result => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };
  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === "" || email === "";
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <TextField
          required
          label="Email"
          style={{ margin: 8 }}
          variant="outlined"
          name="email"
          value={email}
          onChange={this.onChange}
        />

        <TextField
          required
          label="Password One"
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          style={{ margin: 8 }}
          variant="outlined"
          type="password"
        />

        <TextField
          required
          label="Password Two"
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          style={{ margin: 8 }}
          variant="outlined"
          type="password"
        />

        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          onClick={this.onSubmit}
        >
          SIGN UP
        </Button>
        {error && (
          <p style={{ backgroundColor: "#fff", color: "#aa0000" }}>
            {error.message}
          </p>
        )}
      </Grid>
    );
  }
}

export default withRouter(withFirebase(SignupBaseForm));

export { SignUpLink };*/

import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import { Grid, TextField, Button } from "@material-ui/core";

import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";

const SignUpLink = () => (
  <p style={{ margin: 10 }}>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const INITIAL_STATE = {
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class SignupBaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    console.log("HHHHHHH", props, "On Signup");
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { email, passwordOne } = this.state;

    this.props.firebase
      .createUserWithEmailAndPassword(email, passwordOne)
      .then((result) => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  };
  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === "" || email === "";
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <TextField
          required
          label="Email"
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          name="email"
          value={email}
          onChange={this.onChange}
        />

        <TextField
          required
          label="Password One"
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          type="password"
        />

        <TextField
          required
          label="Password Two"
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          type="password"
        />

        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10, height: 50 }}
          disabled={isInvalid}
          onClick={this.onSubmit}
        >
          SIGN UP
        </Button>
        {error && (
          <p style={{ backgroundColor: "#fff", color: "#aa0000" }}>
            {error.message}
          </p>
        )}
      </Grid>
    );
  }
}

export default withRouter(withFirebase(SignupBaseForm));

export { SignUpLink };
