import React, { useState, useEffect,useMemo } from "react";
import { Box, Card, IconButton, Typography, Tooltip } from "@material-ui/core";
import { GetApp,KeyboardArrowLeft,KeyboardArrowRight } from "@material-ui/icons";
import { withDevice } from "../device";
import dateformat from "dateformat";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { MONTHLY_TEMP_REPORT,MONTHLY_TEMP_REPORT_EXCEL } from "../../../utils/url";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";


const timeArr = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];


  const TempTable = ({ data }) => {
    const [columnDefs, setColumnDefs] = useState([
      {
        field: "_id",
        headerName: "Day",
        width: 100,
        minWidth: 80,
        maxWidth: 120,
      },
      
      {
        field: timeArr[0],
        valueGetter: (p) => {
          return p.data.data[0];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[1],
        valueGetter: (p) => {
          return p.data.data[1];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[2],
        valueGetter: (p) => {
          return p.data.data[2];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[3],
        valueGetter: (p) => {
          return p.data.data[3];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[4],
        valueGetter: (p) => {
          return p.data.data[4];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[5],
        valueGetter: (p) => {
          return p.data.data[5];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[6],
        valueGetter: (p) => {
          return p.data.data[6];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[7],
        valueGetter: (p) => {
          return p.data.data[7];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[8],
        valueGetter: (p) => {
          return p.data.data[8];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[9],
        valueGetter: (p) => {
          return p.data.data[9];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[10],
        valueGetter: (p) => {
          return p.data.data[10];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[11],
        valueGetter: (p) => {
          return p.data.data[11];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[12],
        valueGetter: (p) => {
          return p.data.data[12];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[13],
        valueGetter: (p) => {
          return p.data.data[13];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[14],
        valueGetter: (p) => {
          return p.data.data[14];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[15],
        valueGetter: (p) => {
          return p.data.data[15];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[16],
        valueGetter: (p) => {
          return p.data.data[16];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[17],
        valueGetter: (p) => {
          return p.data.data[17];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[18],
        valueGetter: (p) => {
          return p.data.data[18];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[19],
        valueGetter: (p) => {
          return p.data.data[19];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[20],
        valueGetter: (p) => {
          return p.data.data[20];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[21],
        valueGetter: (p) => {
          return p.data.data[21];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[22],
        valueGetter: (p) => {
          return p.data.data[22];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
  
      {
        field: timeArr[23],
        valueGetter: (p) => {
          return p.data.data[23];
        },
        valueFormatter: (p) => {
          return p.value === -256 ? "-" : p.value;
        },
        cellStyle: (params) => {
          if (params.value > -20 || params.value === -256) {
            return { color: "white", backgroundColor: "red" };
          }
          return null;
        },
      },
    ]);
  
    const defaultCollDef = useMemo(
      () => ({
        resizable: true,
        sortable: true,
        width: 120,
        minWidth: 80,
        maxWidth: 130,
      }),
      []
    );
  
    return (
      <div className="ag-theme-alpine" style={{ flex: 1,paddingBottom:10 }}>
  
  <AgGridReact
      rowData={data}
      columnDefs={columnDefs}
      defaultColDef={defaultCollDef}
    />
       
      </div>
      
    );
  };




const MonthlyTemperatureLog = ({deviceParam})=>{

    const [data, setData] = useState([]);
    const [date, setDate] = useState(new Date());
    const { setTitle,device } = deviceParam;


    useEffect(()=>{
        console.log(device)
        setTitle("Monthly Temperature Log of "+device.registration_number)

    },[])

    useEffect(()=>{

        axios.post(MONTHLY_TEMP_REPORT,{
            device_id:device.id,
            registration_number:device.registration_number,
            year:date.getFullYear(),
            month:date.getMonth()
        }).then(response=>setData(response.data))
        .catch(err=>console.log(err))

    },[device,date])


    const downloadFile=()=>{
        axios.post(MONTHLY_TEMP_REPORT_EXCEL,{
            device_id:device.id,
            registration_number:device.registration_number,
            year:date.getFullYear(),
            month:date.getMonth()
        },{responseType: "blob"}).then(response=>{
            const file = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
        .catch(err=>console.log(err))
    }


    const nextClick=()=>{
        setDate((oldDate) => {
            const bbb = new Date(oldDate);
            bbb.setMonth(bbb.getMonth() + 1);
            return bbb;
        });
    }

    const prevClick=()=>{
        setDate((oldDate) => {
            const bbb = new Date(oldDate);
            bbb.setMonth(bbb.getMonth() - 1);
            return bbb;
        });
    }


    return(
        <Box
        display="flex"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: grey[300],
        }}
      >
        <Card style={{ width: "100%", margin: 10 }}>
          <Box
            display="flex"
            flexDirection="column"
            style={{ width: "100%", height: "100%", padding: 10 }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: 10 }}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="button">Day Count</Typography>
                {data && (
                  <Typography variant="body1">{data.length} Nos</Typography>
                )}
              </Box>

              <Box display="flex" alignItems="center">
              <Tooltip  title="Previous">
                  <IconButton onClick={prevClick}>
                    <KeyboardArrowLeft />
                  </IconButton>
                </Tooltip>
                <Typography style={{marginLeft:20,marginRight:20}} variant="button">Report on {dateformat(date, "mmmm-yyyy")}</Typography>

                <Tooltip title="Next">
                  <IconButton onClick={nextClick}>
                  <KeyboardArrowRight />
                  </IconButton>
                </Tooltip>
              </Box>

              <Tooltip style={{margin:10}} title="Download">
                  <IconButton onClick={downloadFile}>
                    <GetApp />
                  </IconButton>
                </Tooltip>
  
              {/* <Box display="flex"  justifyContent="space-between"
              alignItems="center">
                <MyDatePicker selectedDate={date} setSelectedDate={setDate} />
                <Tooltip style={{margin:10}} title="Download">
                  <IconButton onClick={downloadFile}>
                    <GetApp />
                  </IconButton>
                </Tooltip>
              </Box> */}
  
              {/* <TextField
                                  placeholder="Search ......."
                                  onChange={handleChange}
                                  name="search"
                                  value={search}
                                  InputProps={{
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              <Search
                                                  style={{
                                                      color: grey[700],
                                                      padding: 4,
                                                      cursor: "pointer",
                                                  }}
                                              />
                                          </InputAdornment>
                                      ),
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton onClick={() => setSearch("")}>
                                                  <Clear
                                                      style={{
                                                          color: grey[700],
                                                          padding: 4,
                                                          cursor: "pointer",
                                                      }}
                                                  />
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  }}
                              /> */}
            </Box>
            <TempTable data={data} />
          </Box>
        </Card>
      </Box>
    )
}

export default withDevice(MonthlyTemperatureLog);