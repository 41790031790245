import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import MainPage from "../Main";
import LandingPage from "../Landing";
import EmailVerifiedPage from "../EmailVarified";

import { connect } from "react-redux";
import { getAuthUser } from "../../actions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { authUser: null };
  }

  loadComponent = () => {
    if (this.state.uid) {
      if (this.state.emailVerified) {
        return <MainPage />;
      } else {
        return <EmailVerifiedPage />;
      }
    } else {
      return <LandingPage authUser={this.state.authUser} />;
    }
  };

  componentDidMount() {
    // this.listener = this.props.getttAuthUser(this.props.firebase.currentUser());
    this.listener = this.props.firebase.auth.onAuthStateChanged((user) => {
      this.props.getttAuthUser(user, this.props.firebase);
      //this.authUser = user;
    });

    // this.props.firebase.messaging
    //   .requestPermission()
    //   .then(() => this.props.firebase.messaging.getToken())
    //   .then((token) => console.log("Token is", token))
    //   .catch((err) => console.log(err));

    Notification.requestPermission()
      .then(() => this.props.firebase.messaging.getToken())
      .then((token) => console.log("Token is", token))
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    // console.log("Component Will UnMount Called in App");
    this.listener();
  }

  render() {
    return <LandingPage />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getttAuthUser: (firebaseUser, firebase) =>
      dispatch(getAuthUser(firebaseUser, firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withFirebase(App)));
