import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateUser } from "../../actions";

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Box,
  CircularProgress,
} from "@material-ui/core";

import { withFirebase } from "../Firebase";
import { withDevice } from "../web/device";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const initialErrorState = { error: false, message: "", fieldId: 0 };
const initialUser = {
  name: "",
  email: "",
  contact: "",
  organization_name: "",
  address: "",
};

const UpdateUser = ({ open, setOpen, deviceParam, updateUser, firebase }) => {
  const { user } = deviceParam;
  const [show, setShow] = useState(false);

  const [usr, setUsr] = useState({ ...initialUser });
  const [err, setErr] = useState(initialErrorState);

  const handleChange = (e) => {
    const cUser = { ...usr };
    cUser[e.target.name] = e.target.value;
    setUsr(cUser);
  };

  const resolve = () => {
    setShow(false);
    setOpen(false);
    setUsr({ ...initialUser });
  };

  const handleSubmit = (e) => {
    if (usr.name === "") {
      setErr({
        error: true,
        message: "Name Should Not Empty",
        fieldId: 1,
      });
    } else if (usr.contact === "") {
      setErr({
        error: true,
        message: "Contact Should Not Empty",
        fieldId: 3,
      });
    } else if (usr.organization_name === "") {
      setErr({
        error: true,
        message: "Organization Name Should Not Empty",
        fieldId: 4,
      });
    } else if (usr.address === "") {
      setErr({
        error: true,
        message: "Address Should Not Empty",
        fieldId: 5,
      });
    } else {
      setErr(initialErrorState);
      setShow(true);
      updateUser(usr, user, firebase, resolve);
    }
  };

  useEffect(() => {
    setUsr({ ...user });
  }, [user]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textTransform: "uppercase" }}
      >
        Update User
      </DialogTitle>

      {show && (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}

      <DialogContent>
        <TextField
          autoFocus
          error={err.error && err.fieldId === 1}
          label={err.error && err.fieldId === 1 ? err.message : "Name"}
          name="name"
          variant="outlined"
          onChange={handleChange}
          value={usr.name}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={err.error && err.fieldId === 2}
          label={err.error && err.fieldId === 2 ? err.message : "Email"}
          name="email"
          variant="outlined"
          onChange={handleChange}
          value={usr.email}
          fullWidth
          style={{ marginTop: 10 }}
          disabled
        />

        <TextField
          error={err.error && err.fieldId === 3}
          label={err.error && err.fieldId === 3 ? err.message : "Contact"}
          name="contact"
          variant="outlined"
          onChange={handleChange}
          value={usr.contact}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={err.error && err.fieldId === 4}
          label={err.error && err.fieldId === 4 ? err.message : "Organization"}
          name="organization_name"
          variant="outlined"
          onChange={handleChange}
          value={usr.organization_name}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={err.error && err.fieldId === 5}
          label={err.error && err.fieldId === 5 ? err.message : "Address"}
          name="address"
          variant="outlined"
          onChange={handleChange}
          value={usr.address}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen((old) => !old)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (newData, oldData, firebase, resolve) =>
      dispatch(updateUser(newData, oldData, firebase, resolve)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(UpdateUser)));
