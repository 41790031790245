import React, { useState, useEffect } from "react";

import { withDevice } from "../device";
import { withFirebase } from "../../Firebase";
import { connect } from "react-redux";
import { userSharedDevices, deleteUserSharedDevice } from "../../../actions";
import { getVehicleType } from "../../../utils/types";
import { grey } from "@material-ui/core/colors";
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import {
  Search,
  Clear,
  DeleteOutline,
  ReportOutlined,
  LocationOnOutlined,
} from "@material-ui/icons";

const SharedDevicesTable = ({
  sharedDevices,
  deleteUserSharedDevice,
  reportClick,
  sharedDevicesDailyReport,
  handleMapClick,
  firebase,
}) => {
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [devices, setDevices] = useState([]);
  const headerStyle = {
    color: grey[700],
  };

  const mapClick = (device) => {
    if (device.geo) {
      handleMapClick(device);
    } else {
      const ref = firebase.singleDeviceRef(device.id).child("geo");
      ref.on("value", (snapshop) => {
        ref.off();
        if (snapshop.val()) {
          device["geo"] = snapshop.val();
          handleMapClick(device);
        }
      });
    }
  };

  const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: grey[100] };
    } else {
      return { backgroundColor: grey[300] };
    }
  };

  const handleChange = (e) => {
    setSearch((old) => e.target.value);
  };
  const call = () => {
    setVisible(false);
  };

  const deleteSharedDevice = (sd) => {
    setVisible(true);
    //deleteDevice(sd, call);
    deleteUserSharedDevice(sd, call, firebase);
  };



  useEffect(() => {

    const filterDevices = () => {
      if (search === "") {
        setDevices([...sharedDevices]);
      } else {
        setDevices(
          [...sharedDevices].filter((sd) => {
            if (
              sd.device.id &&
              sd.device.registration_number &&
              sd.device.driver_name
            ) {
              return (
                sd.device.id.toLowerCase().includes(search) ||
                sd.device.registration_number.toLowerCase().includes(search) ||
                sd.device.driver_name.toLowerCase().includes(search)
              );
            } else if (sd.device.id && sd.device.registration_number) {
              return (
                sd.device.id.toLowerCase().includes(search) ||
                sd.device.registration_number.toLowerCase().includes(search)
              );
            } else {
              return sd.device.id.toLowerCase().includes(search);
            }
          })
        );
      }
    };


    filterDevices();
  }, [search, sharedDevices]);

  return (
    <Card style={{ width: "100%", height: "100%", padding: 10 }}>
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: "100%", height: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Typography>Shared Device List ( {sharedDevices.length} )</Typography>

          {visible && <CircularProgress color="secondary" />}

          <TextField
            placeholder="Search"
            onChange={handleChange}
            name="search"
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    style={{ color: grey[700], padding: 4, cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")}>
                    <Clear
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box display="flex" style={{ width: "100%", height: "100%" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={devices.length}
                  rowGetter={({ index }) => devices[index]}
                  rowStyle={({ index }) => rowStyle(index)}
                  headerStyle={headerStyle}
                >
                  <Column
                    width={70}
                    flexGrow={1}
                    label="ID"
                    dataKey="device"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.id : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={70}
                    flexGrow={1}
                    label="Reg No"
                    dataKey="device"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.registration_number : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={70}
                    flexGrow={1}
                    label="Model"
                    dataKey="device"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.vehicle_model : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={70}
                    flexGrow={1}
                    label="Driver"
                    dataKey="device"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.driver_name : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={70}
                    flexGrow={1}
                    label="Driver Phone"
                    dataKey="device"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.driver_phone : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={70}
                    flexGrow={1}
                    label="Type"
                    dataKey="device"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData
                          ? getVehicleType(cellData.vehicle_type)
                          : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    headerStyle={{ textAlign: "right", marginRight: 20 }}
                    width={70}
                    flexGrow={3}
                    label="Action"
                    dataKey="action"
                    cellRenderer={({ rowData }) => (
                      <Box display="flex" justifyContent="flex-end">

                        <Tooltip title="Tracking">

                          <IconButton
                            style={{ marginLeft: 4, marginRight: 4 }}
                            onClick={() => mapClick(rowData.device)}
                          >
                            <LocationOnOutlined />
                          </IconButton>

                        </Tooltip>

                        <Tooltip title="Daily Report">
                          <IconButton
                            onClick={() => sharedDevicesDailyReport(rowData.device)}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <ReportOutlined />
                          </IconButton>

                        </Tooltip>
                        <Tooltip title="Monthly Report">
                          <IconButton
                            onClick={() => reportClick(rowData.device)}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <ReportOutlined />
                          </IconButton>

                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => deleteSharedDevice(rowData)}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>



                      </Box>
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Box>
    </Card>
  );
};

const SharedDevices = ({
  deviceParam,
  authUser,
  firebase,
  sharedDevices,
  deleteUserSharedDevice,
  getUserSharedDevices,
}) => {
  const { setTitle, handleMapClick, reportClick,sharedDevicesDailyReport } = deviceParam;

  const filteredDevices = () => {
    return [...sharedDevices].filter((sd) => sd.device !== null);
  };

  useEffect(() => {
    setTitle("Shared Vehicle List");

    if (sharedDevices.length === 0) {
      getUserSharedDevices(authUser.webuid, firebase);
    }
  }, []);
  return (
    <Box
      display="flex"
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <SharedDevicesTable
        sharedDevices={filteredDevices()}
        handleMapClick={handleMapClick}
        firebase={firebase}
        deleteUserSharedDevice={deleteUserSharedDevice}
        reportClick={reportClick}
        sharedDevicesDailyReport={sharedDevicesDailyReport}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSharedDevices: (uid, firebase) =>
      dispatch(userSharedDevices(uid, firebase)),
    deleteUserSharedDevice: (sharedDevice, resolve, firebase) =>
      dispatch(deleteUserSharedDevice(sharedDevice, resolve, firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(SharedDevices)));
