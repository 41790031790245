import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../Firebase";

import {
  addUserDevice,
  setUserDevices,
  getUserDevices,
} from "../../../actions/user_devices_action";
import MyTable from "./table";
import { grey } from "@material-ui/core/colors";

import EditDevice from "./edit_device";
import Settings from "./settings";
import ShareDevice from "./share_device";
import SharedUsers from "./shared_users";
import { withDevice } from "../device";
import { Box } from "@material-ui/core";
import DeviceTableState from "./table_state";
import posed, { PoseGroup } from "react-pose";

const MyPage = posed.div({
  enter: {
    opacity: 1,

    y: 0,
    x: 0,
    transition: {
      y: { type: "spring", stiffness: 1000, damping: 15 },
      default: { duration: 300 },
      // duration: 500,
    },
  },
  exit: {
    y: -100,
    x: -100,
    delay: 100,
    opacity: 0,
    transition: { duration: 500 },
  },
});

const DeviceList = ({ firebase, userDevices, getUserDevices,setUserDevices, deviceParam }) => {
  const {  setTitle } = deviceParam;
  const [editOpen, setEditOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);
  const [shareDeviceOpen, setShareDeviceOpen] = useState(false);
  const [sharedUsersOpen, setSharedUsersOpen] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  const [clickState, setClickState] = useState(-1);

  useEffect(() => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    //getUserDevices(authUser.webuid, firebase);
    if (userDevices.length <= 0) {
      getUserDevices(authUser.webuid, firebase);
    }
  }, []);

  useEffect(() => {
    setTitle("List of All Vehicles");
  }, []);

  

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: grey[300],
        padding: 10,
      }}
    >
      <PoseGroup>
        {statusVisible && (
          <MyPage key="hhhh">
            <DeviceTableState
              userDevices={userDevices}
              setClickState={setClickState}
            />
          </MyPage>
        )}
      </PoseGroup>
      {/* {statusVisible && (
        
      )} */}

      <Box display="flex" flexGrow="1">
        <MyTable
          list={userDevices}
          title="Device List"
          setEditOpen={setEditOpen}
          setSettingOpen={setSettingOpen}
          setShareDeviceOpen={setShareDeviceOpen}
          setSharedUsersOpen={setSharedUsersOpen}
          setStatusVisible={setStatusVisible}
          statusVisible={statusVisible}
          clickState={clickState}
        />
      </Box>

      <EditDevice open={editOpen} setOpen={setEditOpen} />
      <Settings open={settingOpen} setOpen={setSettingOpen} />
      <ShareDevice open={shareDeviceOpen} setOpen={setShareDeviceOpen} />
      <SharedUsers open={sharedUsersOpen} setOpen={setSharedUsersOpen} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUserDevice: (device) => dispatch(addUserDevice(device)),
    setUserDevices: (devices) => dispatch(setUserDevices(devices)),
    getUserDevices: (uid, firebase) => dispatch(getUserDevices(uid, firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(DeviceList)));
