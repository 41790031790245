import * as geolib from 'geolib';
import moment from 'moment';
import axios from 'axios';

export const AUTHORIZATION_KEY = 'BEARER ####rangs.sultantrack.com!!!!$$###';

const plurl =
  'https://eu1.locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15';

export const getRotation = (oldGeo, newGeo) =>
  geolib.getGreatCircleBearing(
    {
      latitude: oldGeo.lat,
      longitude: oldGeo.lng,
    },
    { latitude: newGeo.lat, longitude: newGeo.lng }
  );

export const getCenter = (devices) => {
  let center = geolib.getCenter(
    devices
      .filter((x) => x.geo)
      .map((x) => ({ latitude: x.geo.lat, longitude: x.geo.lng }))
  );
  return { lat: center.latitude, lng: center.longitude };
};

export const lastUpdate = (time) => {
  return time ? moment(time).format('MMMM DD YYYY, h:mm:ss a') : 'undefined';
};

export const stop_duration = (dateStr) => {
  let updateTime = new Date(dateStr);
  let currentDate = new Date();
  let diff = Math.floor((currentDate.getTime() - updateTime.getTime()) / 1000);

  if (diff <= 30) {
    return 'RUNNING';
  } else if (diff > 30 && diff < 60) {
    return diff + ' seconds ago';
  } else if (diff > 60 && diff < 3600) {
    let min = Math.floor(diff / 60);
    let sec = diff % 60;
    return min + ' min ' + sec + ' secs ago';
  } else if (diff > 3600 && diff < 3600 * 24) {
    let hr = Math.floor(diff / 3600);
    let rem = diff % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return hr + ' hrs ' + min + ' min ' + sec + ' secs ago';
  } else {
    let day = Math.floor(diff / (3600 * 24));
    let hrRem = diff % (3600 * 24);
    let hr = Math.floor(hrRem / 3600);
    let rem = hrRem % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return day + ' days ' + hr + ' hrs ' + min + ' min ' + sec + ' secs ago';
  }

  // return diff
};

export const getAddress = (lat, lng) => {
  let url = plurl + '&lat=' + lat + '&lon=' + lng + '&format=json';
  // console.log('Calll Address API');
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data.display_name) {
          resolve(response.data.display_name);
        }
      })
      .catch((err) => reject(err));
  });
};

export const getDistance = (oldGeo, newGeo) => {
  return geolib.getDistance(
    { latitude: oldGeo.lat, longitude: oldGeo.lng },
    { latitude: newGeo.lat, longitude: newGeo.lng }
  );
};
