import { USER_ALERTS, DELETE_ALERT, DELETE_MANY_ALERT } from "../utils/types";

const alertReducers = (state = [], action) => {
  if (action.type === USER_ALERTS) {
    return action.payload;
  } else if (action.type === DELETE_ALERT) {
    let alerts = [...state].filter((alert) => alert._id !== action.payload._id);
    return alerts;
  } else if (action.type === DELETE_MANY_ALERT) {
    let alerts = [...state].filter(
      (alert) => !action.payload.includes(alert._id)
    );
    return alerts;
  } else {
    return state;
  }
};

export default alertReducers;
