import React, { Component } from "react";

import { Grid, TextField, Button } from "@material-ui/core";

import { withFirebase } from "../Firebase";
import { withRouter, Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import axios from "axios";

import { USERS_URL } from "../../utils/url";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class LoginBaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((user) => {
        return {
          name: user.displayName,
          image: user.photoURL,
          email: email,
          contact: user.phoneNumber,
        };
      })
      .then((fireUser) => {
        this.props.firebase.idToken().then((idToken) => {
          axios
            .post(USERS_URL, fireUser, {
              headers: { Authorization: "Bearer " + idToken },
            })
            .then((user) => {
              this.setState({ ...INITIAL_STATE });
              this.props.history.push(ROUTES.LANDING);
            })
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => this.setState({ error: err }));
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <TextField
          required
          label="Email"
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          name="email"
          value={email}
          onChange={this.onChange}
        />

        <TextField
          required
          label="Password"
          name="password"
          value={password}
          onChange={this.onChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          type="password"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10, height: 50 }}
          disabled={isInvalid}
          onClick={this.onSubmit}
        >
          Sign In
        </Button>

        {error && <p>{error.message}</p>}
      </Grid>
    );
  }
}

const GoogleButton = (props) => {
  const buttonClick = (e) => {
    props.firebase
      .googleSignIn()
      .then((result) => {
        let user = {
          name: result.user.displayName,
          email: result.user.email,
          image: result.user.photoURL,
          contact: result.user.phoneNumber | "",
        };

        props.firebase.idToken().then((idToken) => {
          axios
            .post(USERS_URL, user, {
              headers: { Authorization: "Bearer " + idToken },
            })
            .then((user) => {
              return props.history.push(ROUTES.LANDING);
            })
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Button
      style={{ marginTop: 10, width: "100%", backgroundColor: "#db3236" }}
      onClick={buttonClick}
      variant="contained"
      color="primary"
    >
      <FontAwesomeIcon icon={faGoogle} /> &nbsp; Google Login
    </Button>
  );
};

const MyButton = withRouter(withFirebase(GoogleButton));

const LoginLink = () => (
  <p style={{ margin: 10 }}>
    Already have an account? <Link to={ROUTES.SIGN_IN}>Login</Link>
  </p>
);

const Logout = (props) => {
  const handleLogout = (e) => {
    props.firebase.doSignOut();
    localStorage.removeItem("authUser");
  };
  return (
    <div>
      <Button onClick={handleLogout} color="inherit">
        Logout
      </Button>
    </div>
  );
};

const LogoutButton = withFirebase(Logout);

export { MyButton, LoginLink, LogoutButton };

export default withRouter(withFirebase(LoginBaseForm));
