import React from "react";

import { Typography, Box, Card, Divider, Button } from "@material-ui/core";

import { LoginBaseFrom, MyButton } from "../Misc";

import bcImage from "../../images/vts_ab.gif";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { withRouter } from "react-router-dom";
import { SIGN_UP } from "../../constants/routes";

const SigninPage = ({ history }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundImage: "linear-gradient(to bottom right, #4880EC, #019CAD)",
        width: "100%",
        height: "100vh",
      }}
    >
      <Card square style={{ padding: 10, width: "60%" }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <AccountCircleIcon style={{ fontSize: "2rem", color: "#0068bf" }} />
            <Typography
              style={{ color: "#0068bf", marginLeft: 10 }}
              variant="h6"
            >
              {" "}
              WELCOME TO SULTAN TRACKER
            </Typography>
          </Box>

          <Box display="flex">
            <Box
              display="flex"
              flexGrow="1"
              style={{ maxWidth: "50%", overflow: "hidden", padding: 10 }}
            >
              <img src={bcImage} alt="vts" />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              flexGrow="1"
              style={{ padding: 10 }}
            >
              <LoginBaseFrom />

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 4 }}
              >
                <Typography>Don't have an account?</Typography>
                <Button color="secondary" onClick={() => history.push(SIGN_UP)}>
                  Signup
                </Button>
              </Box>

              <Divider />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 4 }}
              >
                <Typography>Social Media Login</Typography>
              </Box>

              <Divider />
              <MyButton />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default withRouter(SigninPage);
