import React, { useState, useEffect } from 'react';
import { utils, writeFileXLSX } from 'xlsx-js-style';
import { connect } from 'react-redux';
import { grey } from '@material-ui/core/colors';
import { Card, IconButton, Typography, Tooltip } from '@material-ui/core';

import { GetApp } from '@material-ui/icons';
import { withFirebase } from '../../Firebase';
import { withDevice } from '../device';
import { InActiveTableData } from './virtual_table';

let headers = [
  'ID',
  'CODE',
  'ENGINE NO',
  'SIM NUMBER',
  'CUSTOMER NAME',
  'CUSTOMER CONTACT',
  'LAST UPDATE',
  'INACTIVE DAYS',
];

const EmptyFeedBack = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography variant="h4">No Data Found</Typography>
    </div>
  );
};

const MonthlyInactiveDevices = ({ userDevices, deviceParam }) => {
  const { setTitle } = deviceParam;

  const [data, setData] = useState([]);

  const getColumnWidth = (data) => {
    let arr = [
      {
        wch: data.reduce((w, r) => Math.max(w, r.id.length), headers[0].length),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.code.trim().length),
          headers[1].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.registration_number.trim().length),
          headers[2].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.device_sim_number.trim().length),
          headers[3].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.driver_name.trim().length),
          headers[4].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.driver_phone.trim().length),
          headers[5].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.update_time.trim().length),
          headers[6].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.inactive_days.length),
          headers[7].length
        ),
      },
    ];

    return arr;
  };

  useEffect(() => {
    setTitle('Inactive Vehicles');
  }, []);

  useEffect(() => {
    if (userDevices.length > 0) {
      let today = new Date();
      let devices = [...userDevices]
        .filter((x) => x.code && x.geo && x.geo.update_time)
        .filter(
          (y) =>
            Math.floor(
              (today - new Date(y.geo.update_time)) / (1000 * 60 * 60 * 24)
            ) >= 7
        );

      devices.sort(
        (a, b) => new Date(b.geo.update_time) - new Date(a.geo.update_time)
      );
      // console.log(devices[0], devices.length);
      setData(devices);
    }
  }, [userDevices]);

  const downloadFile = (e) => {
    let newData = [...data].map((x) => ({
      id: x.id,
      code: x.code ? x.code : 'undefined',
      registration_number: x.registration_number,
      device_sim_number: x.device_sim_number,
      driver_name: x.driver_name ? x.driver_name : 'undefined',
      driver_phone: x.driver_phone ? x.driver_phone : 'undefined',
      update_time: x.geo.update_time,
      inactive_days: Math.floor(
        (new Date() - new Date(x.geo.update_time)) / (1000 * 60 * 60 * 24)
      ),
    }));
    const ws = utils.json_to_sheet(newData);
    ws['!cols'] = getColumnWidth(newData);
    utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Inactive');
    writeFileXLSX(wb, 'inactive.xlsx');
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                padding: 10,
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  padding: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="button">Total Vehicles</Typography>
                  <Typography variant="body1">{data.length} Nos</Typography>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  padding: 10,
                  alignItems: 'center',
                }}
              >
                <Tooltip title="Download">
                  <IconButton onClick={downloadFile}>
                    <GetApp fontSize="large" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <div style={{ width: '98%' }}>
                {data &&
                  (data.length === 0 ? (
                    <EmptyFeedBack />
                  ) : (
                    <InActiveTableData data={data} />
                  ))}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(
  withFirebase(withDevice(MonthlyInactiveDevices))
);
