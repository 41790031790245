import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  IconButton,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Button
} from "@material-ui/core";
import { GetApp,KeyboardArrowLeft,KeyboardArrowRight ,KeyboardArrowUp,KeyboardArrowDown} from "@material-ui/icons";
import { withDevice } from "../device";
import dateformat from "dateformat";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { MONTHLY_PTIO_REPORT } from "../../../utils/url";


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);


  const onButtonClick = x=>{
      let url="https://www.google.com/maps/search/?api=1&query="+x.lat+","+x.lng
      window.open(url);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row._id}
        </TableCell>
        <TableCell align="left">{timeFormat(row.first_start)}</TableCell>
        <TableCell align="left">{timeFormat(row.last_stop)}</TableCell>
        <TableCell align="right">{getduration(row.total_duration)}</TableCell>
        <TableCell align="center">{row.location_found}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell align="right">Duration</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="center">View</TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.spans.map((span) => (
                    <TableRow key={span.start}>
                      <TableCell component="th" scope="row">
                        {timeFormat(span.start)}
                      </TableCell>
                      <TableCell>{timeFormat(span.end)}</TableCell>
                      <TableCell align="right">{getduration(span.duration)}</TableCell>
                      <TableCell align="right">
                        {span.status}
                      </TableCell>
                      <TableCell align="center">
                        {span.status==="STATIC"?(<Button variant="text" color="primary" onClick={()=>onButtonClick(span.position)}>View</Button>):(
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <Button variant="text" color="primary" onClick={()=>onButtonClick(span.start_position)}>Start</Button>
                          <Button variant="text" color="primary" onClick={()=>onButtonClick(span.end_position)}>End</Button>
                          </Box>
                        )}
                      
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



const PTIOTable = ({ data }) => {
   
  
    return (
     

<TableContainer style={{ maxHeight: 450,flex:1 }}>
  
    <Table stickyHeader sx={{
    height: "max-content"
  }} aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align="center">Day</TableCell>
          <TableCell align="left">First Start</TableCell>
          <TableCell align="left">Last Stop</TableCell>
          <TableCell align="right">Duration</TableCell>
          <TableCell align="center">Location Found</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <Row key={row._id} row={row} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
    );
  };


  const timeFormat=(dateStr)=>{
    let timeArr = dateStr.split("T")[1].split(".")[0].split(":")
    let ampm = parseInt(timeArr[0]) >= 12 ? 'PM' : 'AM';
    let hours = parseInt(timeArr[0]) % 12;
    hours = hours ? hours : 12;
    hours = hours.toString().padStart(2, '0');
    return hours + ':' + timeArr[1]+":"+timeArr[2] + ' ' + ampm;
}

const getduration = time=>{
  if(time>=3600){
    let hr = Math.floor(time/3600);
    let rem = time%3600;
    let min = Math.floor(rem/60);
    let sec = rem%60;
    return hr+" hr "+min.toString().padStart(2,0)+" min "+sec.toString().padStart(2,0)+" sec"
  }else{
    let min = Math.floor(time/60);
    let sec = time%60;
    return min.toString().padStart(2,0)+" min "+sec.toString().padStart(2,0)+" sec"
  }
}

const MonthlyPTIOReport=({deviceParam})=>{

    const [data, setData] = useState([]);
    const [date, setDate] = useState(new Date());
    const { setTitle,device } = deviceParam;

    useEffect(()=>{
        console.log(device)
        setTitle("Monthly PTIO Report of "+device.registration_number)

    },[])

    useEffect(()=>{

        axios.post(MONTHLY_PTIO_REPORT,{
            BOX_ID:device.id,
            // registration_number:device.registration_number,
            year:date.getFullYear(),
            month:date.getMonth()+1
        }).then(response=>setData(response.data))
        .catch(err=>console.log(err))

    },[device,date])


    const nextClick=()=>{
        setDate((oldDate) => {
            const bbb = new Date(oldDate);
            bbb.setMonth(bbb.getMonth() + 1);
            return bbb;
        });
    }

    const getTotalDuration=()=>{
      let duration = 0
      for(let i=0;i<data.length;i++){
        duration = duration+data[i].total_duration
      }
      return duration
    }

    const prevClick=()=>{
        setDate((oldDate) => {
            const bbb = new Date(oldDate);
            bbb.setMonth(bbb.getMonth() - 1);
            return bbb;
        });
    }
    return (
      <Box
        display="flex"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: grey[300],
        }}
      >
        <Card style={{ width: "100%", margin: 10 }}>
          <Box
            display="flex"
            flexDirection="column"
            style={{ width: "100%", height: "100%", padding: 10 }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: 10 }}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="button">Day Count</Typography>
                {data && (
                  <Typography variant="body1">{data.length} Nos</Typography>
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Tooltip title="Previous">
                  <IconButton onClick={prevClick}>
                    <KeyboardArrowLeft />
                  </IconButton>
                </Tooltip>
                <Typography
                  style={{ marginLeft: 20, marginRight: 20 }}
                  variant="button"
                >
                  Report on {dateformat(date, "mmmm-yyyy")}
                </Typography>

                <Tooltip title="Next">
                  <IconButton onClick={nextClick}>
                    <KeyboardArrowRight />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* // Dummy */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ padding: 10 }}
              >
                <Box display="flex" flexDirection="column" alignItems="end">
                  <Typography variant="button">PTIO Active</Typography>
                  {data && (
                    <Typography variant="body1">{getduration(getTotalDuration())}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <PTIOTable data={data} />
          </Box>
        </Card>
      </Box>
    );
}

export default withDevice(MonthlyPTIOReport)