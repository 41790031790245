import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem
} from "@material-ui/core";

import { grey } from "@material-ui/core/colors";

import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import {
  Search,
  Clear,
  LocationOnOutlined,
  EditOutlined,
  SettingsOutlined,
  ShareOutlined,
  GroupOutlined,
  Visibility,
  VisibilityOff,
  GetApp,
  MoreVert
} from "@material-ui/icons";
import { getVehicleType } from "../../../utils/types";
import { USERS_URL } from "../../../utils/url";
import { withDevice } from "../device";
import axios from 'axios'


const MyTable = ({
  list,
  deviceParam,
  setEditOpen,
  setSettingOpen,
  setShareDeviceOpen,
  setSharedUsersOpen,
  setStatusVisible,
  statusVisible,
  clickState,
}) => {
  const { setDevice, handleMapClick, reportClick,monthlyTemperatureLog,monthlyPTIOReport,dailyTravelReportClick,dailySpeedReportClick,dailyReportClick } = deviceParam;
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [devices, setDevices] = useState([]);
  const [dev, setDev] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event,dev) => {
    // console.log(dev,"IN MAIN PAGE")
    setDev(dev)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const headerStyle = {
    color: grey[700],
  };

  const IN_ACTIVE_DATE = 31

  const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: grey[100] };
    } else {
      return { backgroundColor: grey[300] };
    }
  };

  const handleChange = (e) => {
    setSearch((old) => e.target.value);
  };
  /* const resolve = () => {
    setVisible(false);
  }; */

  const editClick = (device) => {
    setDevice(device);
    setEditOpen(true);
  };
  const settingClick = (device) => {
    setDevice(device);
    setSettingOpen(true);
  };

  const shareClick = (device) => {
    setDevice(device);
    setShareDeviceOpen(true);
  };

  const sharedUsersClick = (device) => {
    setDevice(device);
    setSharedUsersOpen(true);
  };


  const handleReportClick = ()=>{
    reportClick(dev,false)
  }

  const handleMonthlyTemperatureLogClick =()=>{
    monthlyTemperatureLog(dev)
  }

  const handlePTIOReport = ()=>{
    monthlyPTIOReport(dev)
  }


  const handleDailyTravelReportClick=()=>{
    dailyTravelReportClick(dev,false)
  }

  const handleDailySpeedReportClick=()=>{
    dailySpeedReportClick(dev,false)
  }

  const hanleDailyReportClick=()=>{
    dailyReportClick(dev,false)
  }

  const deviceFilter=(device)=>{

    return (
        (device.id && device.id.toLowerCase().includes(search.toLowerCase())) ||
        (device.mask_id && device.mask_id.toLowerCase().includes(search.toLowerCase())) ||
        (device.registration_number && device.registration_number.toLowerCase().includes(search.toLowerCase())) ||
        (device.vehicle_model && device.vehicle_model.toLowerCase().includes(search.toLowerCase())) ||
        (device.device_sim_number && device.device_sim_number.toLowerCase().includes(search.toLowerCase())) ||
        (device.chasis_number && device.chasis_number.toLowerCase().includes(search.toLowerCase())) ||
        (device.code && device.code.toLowerCase().includes(search.toLowerCase())) ||
        (device.driver_name && device.driver_name.toLowerCase().includes(search.toLowerCase())) ||
        (device.driver_phone && device.driver_phone.toLowerCase().includes(search.toLowerCase()))

    )

}

  const filterDevices = () => {
    if (search === "") {
      setDevices([...list]);
    } else {
      setDevices(
        [...list].filter((device) => deviceFilter(device))
      );
    }
  };

  useEffect(() => {
    filterDevices();
  }, [search, list]);

  useEffect(() => {
    const today = new Date();
    if (clickState === 0) {
      setDevices([...list].filter((dev) => {
        return dev.geo
      }));
    } else if (clickState === 1) {
      setDevices([...list].filter((dev) => !dev.geo));
    } else if (clickState === 2) {
      setDevices([...list].filter((dev) => dev.geo).filter(dev=>dev.geo.update_time && Math.floor((today-new Date(dev.geo.update_time))/(1000*60*60*24))<=IN_ACTIVE_DATE));
    } else if (clickState === 3) {
      setDevices([...list].filter((dev) => dev.geo).filter(dev=>!(dev.geo.update_time && Math.floor((today-new Date(dev.geo.update_time))/(1000*60*60*24))<=IN_ACTIVE_DATE)));
     
    }else if(clickState===4){
      setDevices([...list].filter((dev) => dev.geo && dev.geo.acc === "ON"));
    }else if(clickState===5){
      setDevices([...list].filter((dev) => dev.geo && dev.geo.acc === "OFF"));
    }
  }, [clickState]);

  const downloadFile = () => {
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    const url = USERS_URL+authUser.webuid+"/devices/download"

    axios.get(url,{ responseType: "blob" })
      .then(response=>{
        const file = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => console.log(err));
  };

  const downloadInActiveDevices=()=>{
    const today = new Date();
    // today.getMonth
    let inactiveDevice = [...list].filter((dev) => dev.geo).filter(dev=>!(dev.geo.update_time && Math.floor((today-new Date(dev.geo.update_time))/(1000*60*60*24))<=IN_ACTIVE_DATE))

    inactiveDevice.map(x=>{
      let d = new Date(x.geo.update_time)
      x['key'] = d.getMonth()+1+d.getFullYear()*12
      
    })

    let hash = inactiveDevice.reduce((p,c) => (p[c.key] ? p[c.key].push(c) : p[c.key] = [c],p) ,{})
    let newData = Object.keys(hash).map(k => ({key: k, devices: hash[k]}));
    console.log(newData)
  }
  return (
    <Card style={{ width: "100%", height: "100%", padding: 10 }}>
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: "100%", height: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Box display="flex" flexDirection="column">
            <Typography style={{ padding: 2 }}>
              Total Vehicles: ( {list.length} )
            </Typography>
            <Typography style={{ padding: 2 }}>
              Searched Vehicles: ( {devices.length} )
            </Typography>
          </Box>

          {visible && <CircularProgress color="secondary" />}

          <Box display="flex" alignItems="center">
            <Tooltip title={statusVisible ? "Hide States" : "Show States"}>
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => setStatusVisible((old) => !old)}
              >
                {statusVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Download">
              <IconButton style={{ marginRight: 10 }} onClick={downloadFile}>
                <GetApp />
              </IconButton>
            </Tooltip>

            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box display="flex" flexGrow="1">
          <div style={{ width: "100%", height: "100%" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={devices.length}
                  rowGetter={({ index }) => devices[index]}
                  rowStyle={({ index }) => rowStyle(index)}
                  headerStyle={headerStyle}
                >
                  <Column
                    width={60}
                    flexGrow={2}
                    label="ID"
                    dataKey="id"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Reg No"
                    dataKey="registration_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Chasis No"
                    dataKey="chasis_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Model"
                    dataKey="vehicle_model"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Code"
                    dataKey="code"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={2}
                    label="SIM"
                    dataKey="device_sim_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={2}
                    label="Driver/Cus"
                    dataKey="driver_name"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Type"
                    dataKey="vehicle_type"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? getVehicleType(cellData) : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Ignition"
                    dataKey="geo"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.acc : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    headerStyle={{ textAlign: "right", marginRight: 20 }}
                    width={60}
                    flexGrow={12}
                    label="Action"
                    dataKey="action"
                    cellRenderer={({ rowData }) => (
                      <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="Map">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => handleMapClick(rowData)}
                          >
                            <LocationOnOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit Vehicle">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => editClick(rowData)}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Settings">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => settingClick(rowData)}
                          >
                            <SettingsOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Share Vehicle">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => shareClick(rowData)}
                          >
                            <ShareOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Shared Users">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => sharedUsersClick(rowData)}
                          >
                            <GroupOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="More..">
                          <IconButton
                            onClick={(e)=>handleClick(e,rowData)}
                            style={{ marginLeft: 2, marginRight: 2 }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={hanleDailyReportClick}>Daily Report</MenuItem>
                          <MenuItem onClick={handleReportClick}>Monthly Report</MenuItem>
                          <MenuItem onClick={handleDailyTravelReportClick}>Daily Travel Report</MenuItem>
                          <MenuItem onClick={handleDailySpeedReportClick}>Speed Report</MenuItem>


                          {dev && dev.device_model==="S5E" && <MenuItem onClick={handleMonthlyTemperatureLogClick}>Monthly Temperature Log</MenuItem>}

                          { dev && dev.device_model==="S5E" && <MenuItem onClick={handlePTIOReport}>PTIO Report</MenuItem>}
                          
                         
                          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                        </Menu>
                      </Box>
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Box>
    </Card>
  );
};

export default withDevice(MyTable);
