import {
  USER_DEVICES,
  ADD_USER_DEVICE,
  UPDATE_USER_DEVICE,
} from "../utils/types";

const userDevicesReducers = (state = [], action) => {
  if (action.type === USER_DEVICES) {
    return action.payload;
  } else if (action.type === ADD_USER_DEVICE) {
    let userDevices = [...state];
    userDevices.push(action.payload);
    return userDevices;
  } else if (action.type === UPDATE_USER_DEVICE) {
    //console.log("hhjdfdsfdsfhj");
    let userDevices = [...state];
    userDevices[userDevices.indexOf(action.payload.oldDevice)] =
      action.payload.newDevice;

    return userDevices;
  } else {
    return state;
  }
};

export default userDevicesReducers;
