import { Box, Card, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { amber } from "@material-ui/core/colors";
import { Menu, ArrowBack } from "@material-ui/icons";

const AdminNav = ({ menuClick, components, title, backClick }) => {
  return (
    <Card
      square
      style={{ width: "100%", height: 60, backgroundColor: amber[700] }}
    >
      <Box
        display="flex"
        style={{
          width: "100%",
          height: 60,
          paddingLeft: 10,
        }}
        alignItems="center"
      >
        {components.length > 1 && (
          <IconButton onClick={backClick}>
            <ArrowBack />
          </IconButton>
        )}
        <IconButton onClick={menuClick}>
          <Menu />
        </IconButton>
        <Typography variant="h5" style={{ marginLeft: 10 }}>
          {title}
        </Typography>
      </Box>
    </Card>
  );
};

export default AdminNav;
