import React, { useEffect,useState } from "react";

//import MyMarker from "../web/component/my_marker";
import { GoogleMap, LoadScript,TrafficLayer } from "@react-google-maps/api";
import LocationMarker from '../web/component/gmap/location_marker'
import {MAP_API_KEY} from '../../utils/web_utils'

import { withFirebase } from "../Firebase";
import { withDevice } from "../web/device";
import { Box } from "@material-ui/core";

const SingleMap = ({ deviceParam,firebase }) => {
  // console.log(device);
  const { device, setTitle,setDevice } = deviceParam;

  const [center,setCenter] = useState(null)

  const containerStyle = {
    height: "100%",
    width: "100%",
  };

  useEffect(() => {
    setTitle("Map view of " + device.id);
    const ref = firebase.singleDeviceRef(device.id).child("geo");


    if(!device.geo){
      ref.once('value',snapshot=>{
        let geo = snapshot.val()
        let dev ={...device}
        dev.geo = geo

        setDevice(dev)

        // console.log("HOOOOOOOOO")
  
        
      })
    }else{
      setCenter({lat:device.geo.lat,lng:device.geo.lng})
    }

    
  }, [device]);
  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
        {center && <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18}>
          {/* {console.log(device.geo)} */}
          <LocationMarker device={device} />
          <TrafficLayer />
        </GoogleMap>}
      </LoadScript>
  );
};

export default withFirebase(withDevice(SingleMap));
