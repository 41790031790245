// import React,{useState,useEffect} from 'react'
// import {Box,Typography} from '@material-ui/core'
// import axios from 'axios'
// import moment from 'moment'

// // const MAPBOX_TOKEN = "pk.eyJ1Ijoic29oZWwyMTc4IiwiYSI6ImNrNndnMnRlODA3YnEzbm1hdmxtdWFjc2gifQ.2D9mJd0-ojZo7NZ_DpG98A"
// // const placeUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

// const plurl = "https://eu1.locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15"
// // &lat=23.23&lon=90.89&format=json

// const TripPopup = ({location})=>{
//     const [address,setAddress] = useState("")

//     useEffect(()=>{

//         let url = plurl+"&lat="+location.geo.lat+"&lon="+location.geo.lng+"&format=json"

//         //console.log(url)

//         axios.get(url)
//             .then(response=>{
//                 if(response.data.display_name){
//                     setAddress((old) => response.data.display_name);
//                 }
//             })
//             .catch(err=>console.log(err))

//     },[location])

//     return (
//       <Box
//         display="flex"
//         flexDirection="column"
//         style={{ minWidth: 400, maxWidth: 800 }}
//       >

//         <Box display="flex" flexDirection="column" style={{ padding: 10 }}>
//           <Box display="flex" justifyContent="space-between">
//             <Typography
//               style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
//             >
//               ID:
//             </Typography>
//             <Typography style={{fontSize: 12 }}>{location.id}</Typography>
//           </Box>

//           <Box display="flex" justifyContent="space-between">
//             <Typography
//               style={{ textTransform: "uppercase", fontWeight: "bold" ,fontSize: 12}}
//             >
//               Address:
//             </Typography>
//             <Typography style={{fontSize: 12 }}>{address}</Typography>
//           </Box>

//           <Box display="flex" justifyContent="space-between">
//             <Typography
//               style={{
//                 textTransform: "uppercase",
//                 fontWeight: "bold",
//                 fontSize: 12,
//               }}
//             >
//               Ignition:
//             </Typography>
//             <Typography style={{ fontSize: 12 }}>{location.geo.acc}</Typography>
//           </Box>

//           <Box
//           display="flex"
//           justifyContent="space-between"

//         >
//           <Typography
//             style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
//           >
//             Speed:
//           </Typography>
//           <Typography  style={{fontSize: 12 }}>{location.geo.speed} KMPH</Typography>
//         </Box>

//         {location.geo.charging && <Box
//           display="flex"
//           justifyContent="space-between"

//         >
//           <Typography
//             style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
//           >
//             Charging:
//           </Typography>
//           <Typography  style={{fontSize: 12 }}>{location.geo.charging}</Typography>
//         </Box>}

//           <Box display="flex" justifyContent="space-between">
//             <Typography
//               style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
//             >
//               Update Time:
//             </Typography>
//             <Typography style={{fontSize: 12 }}>
//               {location.devicetime
//                 ? moment(location.devicetime).format("MMMM Do YYYY, h:mm:ss a")
//                 : "undefined"}
//             </Typography>
//           </Box>
//         </Box>
//       </Box>
//     );

// }

// export default TripPopup;

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { LOCATIONS_URL } from '../../../../utils/url';

// const MAPBOX_TOKEN = "pk.eyJ1Ijoic29oZWwyMTc4IiwiYSI6ImNrNndnMnRlODA3YnEzbm1hdmxtdWFjc2gifQ.2D9mJd0-ojZo7NZ_DpG98A"
// const placeUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

const plurl =
  'https://eu1.locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15';
// &lat=23.23&lon=90.89&format=json

const TripPopup = ({ location, authUser }) => {
  const [address, setAddress] = useState('');

  // console.log(authUser);

  useEffect(() => {
    let url =
      plurl +
      '&lat=' +
      location.geo.lat +
      '&lon=' +
      location.geo.lng +
      '&format=json';

    //console.log(url)

    axios
      .get(url)
      .then((response) => {
        if (response.data.display_name) {
          setAddress((old) => response.data.display_name);
        }
      })
      .catch((err) => console.log(err));
  }, [location]);

  const removeLocation = () => {
    axios
      .delete(LOCATIONS_URL + location._id)
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err));
    console.log(location);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ minWidth: 400, maxWidth: 800 }}
    >
      <Box display="flex" flexDirection="column" style={{ padding: 10 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            ID:
          </Typography>
          <Typography style={{ fontSize: 12 }}>{location.id}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Address:
          </Typography>
          <Typography style={{ fontSize: 12 }}>{address}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Ignition:
          </Typography>
          <Typography style={{ fontSize: 12 }}>{location.geo.acc}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Speed:
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            {location.geo.speed} KMPH
          </Typography>
        </Box>

        {location.geo.charging && (
          <Box display="flex" justifyContent="space-between">
            <Typography
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: 12,
              }}
            >
              Charging:
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {location.geo.charging}
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Update Time:
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            {location.devicetime
              ? moment(location.devicetime).format('MMMM Do YYYY, h:mm:ss a')
              : 'undefined'}
          </Typography>
        </Box>

        {authUser.is_admin && (
          <Button variant="outlined" onClick={removeLocation}>
            Remove Location
          </Button>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TripPopup);
