import React, { useEffect, useState } from "react";

import { Card, Typography } from "@material-ui/core";
import MyDatePicker from "./daily-report/date_picker";
import UserDeviceAutoComplete from "./daily-report/user_device_autocomplete";
import SharedUserDeviceAutoComplete from "./daily-report/shared_user_devices_autocomplete";
import { MyCard } from "./component-utils";
import { DAILY_REPORT_URL } from "../../../utils/url";

import axios from "axios";

import { withDevice } from "../device";
import { HourlyReport, TripReport, formatDuration } from "./virtual_table";

const DailyReport = ({ deviceParam }) => {
  const { setTitle,fromSharedUser } = deviceParam;
  const [state, setState] = useState({
    selectedDate: new Date(),
    device: { ...deviceParam.device },
    progressVisible: false,
    trip_report: [],
    hourly_report: [],
    total_distance: 0,
    running_time: 0,
  });

  const setSelectedDate = (date) => {
    setState((oldState) => ({ ...oldState, selectedDate: date }));
  };

  const setDevice = (device) => {
    setState((oldState) => ({ ...oldState, device: device }));
  };

  useEffect(() => {
    setTitle(
      "Daily Distance Travel Report of " + state.device.registration_number
    );
  }, [state.device]);

  useEffect(() => {
    if (state.device && state.selectedDate) {
      let postData = {
        device_id: state.device.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        day: state.selectedDate.getDate(),
        device_type: state.device.vehicle_type,
      };
      axios
        .post(DAILY_REPORT_URL, postData)
        .then((response) => {
          setState((oldState) => ({
            ...oldState,
            trip_report: response.data.trip_report,
            hourly_report: response.data.hourly_report,
            total_distance: response.data.total_distance,
            running_time: response.data.running_time,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [state.device, state.selectedDate]);

  return (
    <div
      style={{
        backgroundColor: "#eeeeee",
        height: "100%",
        padding: 10,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <MyCard
            title="Distance"
            value={String(state.total_distance) + " KM"}
          />
          <MyCard title="Running" value={formatDuration(state.running_time)} />
          <MyCard
            title="Fuel"
            value={
              state.device.milage
                ? (state.total_distance / state.device.milage).toFixed(2) +
                  " Lit"
                : "Set Milage"
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 25,
          }}
        >
          <Card style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6">Trip Report</Typography>
              </div>
              <div
                style={{ width: "50%", height: 5, backgroundColor: "#F18905" }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "98%",
                  marginTop: 10,
                }}
              >
                <TripReport trip_report={state.trip_report} />
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ display: "flex", flex: 1 }}>
            <Card style={{ padding: 4 }}>
              <MyDatePicker
                selectedDate={state.selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </Card>
          </div>
          <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
            <Card style={{ padding: 4 }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {fromSharedUser?<SharedUserDeviceAutoComplete setDevice={setDevice} device={state.device}/>:<UserDeviceAutoComplete
                  setDevice={setDevice}
                  device={state.device}
                />}
                
              </div>
            </Card>
          </div>
        </div>

        <div style={{ display: "flex", flex: 1, marginTop: 10 }}>
          <Card style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6">Hourly Travel Distance</Typography>
              </div>
              <div
                style={{ width: "50%", height: 5, backgroundColor: "#F18905" }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "98%",
                  marginTop: 10,
                }}
              >
                <HourlyReport hourly_report={state.hourly_report} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default withDevice(DailyReport);
