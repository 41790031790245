import React, { useState, useEffect, useRef } from "react";
import {
  Slide,
  Box,
  Dialog,
  Card,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Search, Clear } from "@material-ui/icons";
import { withFirebase } from "../Firebase";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { withDevice } from "../web/device";
import MySnackbar from "../web/component/my_snackbar";
import { assignDevice, fetchAllUsers } from "../../actions";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UserList = ({ users, handleAssignClick }) => {
  const [hover, setHover] = useState(-1);
  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true,
    })
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowHeight={70}
            rowCount={users.length}
            rowRenderer={({ key, index, style, parent }) => {
              const user = users[index];
              // console.log(person);
              return (
                <CellMeasurer
                  key={key}
                  cache={cache.current}
                  rowIndex={index}
                  parent={parent}
                >
                  <div style={style}>
                    <ListItem
                      divider
                      key={index}
                      style={
                        hover === index
                          ? { cursor: "pointer", backgroundColor: grey[200] }
                          : { cursor: "pointer" }
                      }
                      onMouseEnter={() => setHover((ii) => index)}
                      onClick={() => handleAssignClick(user)}
                    >
                      <ListItemAvatar>
                        <Avatar src={user.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="button">{user.name}</Typography>
                        }
                        secondary={
                          <Typography variant="body2">
                            {user.contact}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </div>
                </CellMeasurer>
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

const AssignDevice = ({
  open,
  setOpen,
  deviceParam,
  firebase,
  assignDevice,
  fetchAllUsers,
  users,
}) => {
  const { device } = deviceParam;
  const [state, setState] = useState({
    visible: false,
    search: "",
    filterUsers: [],
    snacState: { open: false, message: "", severity: "success" },
  });
  // const [visible, setVisible] = useState(false);
  // const [search, setSearch] = useState("");
  // const [filterUsers, setFilterUsers] = useState([]);
  // const [snacState, setSnacState] = useState({
  //   open: false,
  //   message: "",
  //   severity: "success",
  // });

  const handleChange = (e) => {
    let val = e.target.value;
    setState((old) => ({ ...old, search: val }));
    // setSearch(e.target.value);
  };


  const setSnacState = val =>{
    setState((old) => ({ ...old, snacState: val }));
  }

  const filter = () => {
    setState((old) => ({
      ...old,
      filterUsers: [...users].filter((user) =>
        user.email.toLowerCase().startsWith(state.search.toLowerCase())
      ),
    }));
  };

  const resolve = (val) => {
    if (val) {
      setState((old) => ({
        ...old,
        visible: false,
        snacState: {
          open: true,
          message: "Assign Device Successfully",
          severity: "success",
        },
      }));
    } else {
      

      setState((old) => ({
        ...old,
        visible: false,
        snacState: {
          open: true,
          message: "Already Assign this Device to the user",
          severity: "error",
        },
      }));
    }
  };

  const handleAssignClick = (user) => {
    setState((old) => ({ ...old, visible: true }));
    const data = { uid: user._id, id: device.id };
    assignDevice(data, device, firebase, resolve);
  };

  useEffect(() => {
    if (state.search.length === 0) {
      setState((old) => ({ ...old, filterUsers:[...users] }));
    } else {
      filter();
    }
  }, [state.search]);

  useEffect(() => {
    if (users.length === 0) {
      fetchAllUsers(firebase);

      // console.log("Fetch All Users");
    }
  }, [firebase]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Card style={{ width: 500, height: 500, padding: 10 }}>
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: "100%", height: "100%" }}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" flexGrow="1">
            <Box display="flex" justifyContent="center" style={{ padding: 10 }}>
              <Typography variant="body1">
                Share {device && device.registration_number} to Other Users
              </Typography>
            </Box>

            {state.visible && (
              <Box display="flex" justifyContent="center">
                <CircularProgress color="secondary" />
              </Box>
            )}

            <Box display="flex" justifyContent="center" style={{ padding: 10 }}>
              <TextField
                placeholder="Search User by Email"
                onChange={handleChange}
                fullWidth
                name="search"
                value={state.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setState(old=>({...old,search:""}))}>
                        <Clear
                          style={{
                            color: grey[700],
                            padding: 4,
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box display="flex" flexGrow="1">
              <UserList
                users={state.filterUsers}
                handleAssignClick={handleAssignClick}
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" onClick={() => setOpen(false)}>
              Hide
            </Button>
          </Box>
        </Box>
      </Card>
      <MySnackbar snacState={state.snacState} setSnacState={setSnacState} />
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignDevice: (data, device, firebase, resolve) =>
      dispatch(assignDevice(data, device, firebase, resolve)),
    fetchAllUsers: (firebase) => dispatch(fetchAllUsers(firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(AssignDevice)));
