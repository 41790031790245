import React from 'react'
import {connect} from 'react-redux'
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';



const SharedUserDevicesAutocomplete = ({sharedDevices,setDevice})=>{

    // console.log(props)
    
    return(
        // <div>Hello</div>
        <Autocomplete
            id="combo-box-demo"
            options={[...sharedDevices].filter(dev=>dev.device.id && dev.device.id!=="")}
            getOptionLabel={(device) => device.device.id}
            style={{ width: 300 }}
            onChange={(event,value,reason)=>value && setDevice(value.device)}
            renderInput={(params) => <TextField {...params} label="Select ID" variant="outlined" style={{backgroundColor:"#fff"}} />}
        />
    )


}

const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };


  export default connect(
    mapStateToProps
  ) (SharedUserDevicesAutocomplete)