import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { withDevice } from "../device";
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  userAlert,
  deleteAlert,
  deleteManyAlerts,
} from "../../../actions/alert_action";
import { withFirebase } from "../../Firebase";
import { Search, Clear, DeleteOutline } from "@material-ui/icons";
import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";

const AlertTable = ({ list, deleteManyAlerts }) => {
  const [visible] = useState(false);
  const [search, setSearch] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [fifty, setFifty] = useState(false);
  const [hundred, setHundred] = useState(false);
  const [twohundred, setTwoHundred] = useState(false);

  

  const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: grey[100] };
    } else {
      return { backgroundColor: grey[300] };
    }
  };

  const filterAlerts = () => {
    if (search === "") {
      setAlerts([...list]);
    } else {
      setAlerts(
        [...list].filter((alert) => {
          return (
            alert.title.toLowerCase().includes(search.toLowerCase()) ||
            alert.message.toLowerCase().includes(search.toLowerCase())
          );
        })
      );
    }
  };

  useEffect(() => {
    filterAlerts();
  }, [search, list]);

  const handleChange = (e) => {
    setSearch((old) => e.target.value);
  };

  const contains = (data) => {
    if ([...selected].filter((al) => al._id === data._id).length > 0) {
      return true;
    }
    return false;
  };

  const handleCheckChange = (state, data) => {
    if (state) {
      setSelected([...selected, data]);
    } else {
      setSelected([...selected].filter((alert) => alert._id !== data._id));
    }
  };

  const callback = () => {
    setSelected([]);
    setFifty(false);
    setHundred(false);
    setTwoHundred(false);
  };

  const handleFiftyChange = () => {
    let state = !fifty;
    if (state) {
      const hhh = [...alerts];
      if (hhh.length > 50) {
        setSelected(hhh.slice(0, 50));
      } else {
        setSelected(hhh);
      }
    } else {
      setSelected([]);
    }

    setFifty((old) => !old);
  };

  const handleHundredChange = () => {
    let state = !hundred;
    if (state) {
      const hhh = [...alerts];
      if (hhh.length > 100) {
        setSelected(hhh.slice(0, 100));
      } else {
        setSelected(hhh);
      }
    } else {
      setSelected([]);
    }

    setHundred((old) => !old);
  };

  const handleTwoHundredChange = () => {
    let state = !twohundred;
    if (state) {
      const hhh = [...alerts];
      if (hhh.length > 200) {
        setSelected(hhh.slice(0, 200));
      } else {
        setSelected(hhh);
      }
    } else {
      setSelected([]);
    }

    setTwoHundred((old) => !old);
  };
  return (
    <Card style={{ width: "100%", height: "100%", padding: 10 }}>
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: "100%", height: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Typography>Alerts Count ( {list.length} )</Typography>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={fifty}
                  onChange={(e) => handleFiftyChange()}
                />
              }
              label="50"
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={hundred}
                  onChange={(e) => handleHundredChange()}
                />
              }
              label="100"
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={twohundred}
                  onChange={(e) => handleTwoHundredChange()}
                />
              }
              label="100"
              labelPlacement="end"
            />
          </Box>

          {visible && <CircularProgress color="secondary" />}

          <Box display="flex" alignItems="center">
            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {selected.length > 0 && (
              <IconButton onClick={() => deleteManyAlerts(selected, callback)}>
                <DeleteOutline />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box display="flex" flexGrow="1">
          <div style={{ width: "100%", height: "100%" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={alerts.length}
                  rowGetter={({ index }) => alerts[index]}
                  rowStyle={({ index }) => rowStyle(index)}
                >
                  <Column
                    width={60}
                    flexGrow={1}
                    label="DATE"
                    dataKey="date"
                    cellRenderer={({ cellData, rowData }) => (
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(e) =>
                            handleCheckChange(e.target.checked, rowData)
                          }
                          checked={contains(rowData)}
                        />

                        <Typography variant="caption">
                          {cellData ? cellData : "undefined"}
                        </Typography>
                      </Box>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Title"
                    dataKey="title"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={2}
                    label="MESSAGE"
                    dataKey="message"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Box>
    </Card>
  );
};

const Alerts = ({
  authUser,
  firebase,
  deviceParam,
  userAlerts,
  getAllAlerts,
  deleteManyAlerts,
}) => {
  const { setTitle } = deviceParam;

  useEffect(() => {
    setTitle("All Alerts of " + authUser.name);
    if (userAlerts.length === 0) {
      getAllAlerts(authUser.webuid, firebase);
    }
  }, []);
  return (
    <Box
      display="flex"
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <AlertTable list={userAlerts} deleteManyAlerts={deleteManyAlerts} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAlerts: (webuid, firebase) => dispatch(userAlert(webuid, firebase)),
    deleteAlert: (alert, firebase) => dispatch(deleteAlert(alert, firebase)),
    deleteManyAlerts: (alerts, setSelected) =>
      dispatch(deleteManyAlerts(alerts, setSelected)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(Alerts)));
