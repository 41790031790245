import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sliderImages as images } from "../../data";

const MySlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div
      id="marginTop"
      style={{ width: "100%", height: 600, overflow: "hidden" }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img src={image} style={{ height: 500, width: "100%" }} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MySlider;
