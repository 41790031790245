import React from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { LocalTaxi } from "@material-ui/icons";
import {
  green,
  amber,
  red,
  blueGrey,
  lightBlue,
} from "@material-ui/core/colors";

const AdminTableState = ({ userDevices, setClickState }) => {
  const getActiveCount = () => {
    let count = 0;
    userDevices.map((dev) => dev.geo && count++);
    return count;
  };

  const getInactiveCount = () => {
    return userDevices.length - getActiveCount();
  };

  const getRunningCount = () => {
    let count = 0;
    userDevices.map((dev) => dev.geo && dev.geo.acc === "ON" && count++);
    return count;
  };
  const getStoppedCount = () => {
    let count = 0;
    userDevices.map((dev) => dev.geo && dev.geo.acc === "OFF" && count++);
    return count;
  };

  const getAssignCount = () => {
    let count = 0;
    userDevices.map((dev) => dev.uid && count++);
    return count;
  };

  const getUnAssignCount = () => {
    return userDevices.length - getAssignCount();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{ marginBottom: 10 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setClickState(0)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}
          >
            <LocalTaxi style={{ color: lightBlue[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Active Vehicles</Typography>
            <Typography>{getActiveCount()}</Typography>
          </Box>
        </Card>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setClickState(1)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}
          >
            <LocalTaxi />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>In-active Vehicles</Typography>
            <Typography>{getInactiveCount()}</Typography>
          </Box>
        </Card>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setClickState(2)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}
          >
            <LocalTaxi style={{ color: green[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Running Vehicles</Typography>
            <Typography>{getRunningCount()}</Typography>
          </Box>
        </Card>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setClickState(3)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}
          >
            <LocalTaxi style={{ color: red[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Stopped Vehicles</Typography>
            <Typography>{getStoppedCount()}</Typography>
          </Box>
        </Card>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setClickState(4)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}
          >
            <LocalTaxi style={{ color: red[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Assign Vehicles</Typography>
            <Typography>{getAssignCount()}</Typography>
          </Box>
        </Card>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setClickState(5)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%" }}
          >
            <LocalTaxi style={{ color: red[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>UnAssign Vehicles</Typography>
            <Typography>{getUnAssignCount()}</Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default AdminTableState;
