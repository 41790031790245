import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, TrafficLayer } from "@react-google-maps/api";
import { Box, TextField, InputAdornment, IconButton } from "@material-ui/core";

import { Search, Clear } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { withDevice } from "../device";
import { MAP_API_KEY } from "../../../utils/web_utils";
import debounce from "lodash.debounce";
import ListMarker from './gmap/list_marker'
import ListInfoWindow from './gmap/list_info_window'

let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};



const AllVehicleMap = ({ userDevices, deviceParam }) => {
  const { setTitle } = deviceParam;

  const [search, setSearch] = useState("");
  const [center, setCenter] = useState({ ...defaultLatLng });
  const [searchDevice,setSearchDevice]=useState([])
  const [infoData,setInfoData] = useState(null)

  const containerStyle = {
    height: "100%",
    width: "100%",
  };

  const overlayStyle = {
    left: 0,
    top: 0,
    zIndex: 20000,
  };

  useEffect(() => {
    setTitle("All Vehicles in Map");
  }, []);

  useEffect(() => {
    let ss = [...searchDevice];
    if (ss.length > 0) {
      const latlng = averageGeolocation(ss);
      setCenter(latlng);
    }
  }, [searchDevice]);


  useEffect(()=>{
    if(search===""){
        let ss =[...userDevices].filter(dev=>dev.geo)
        if(ss.length>50){
          ss.sort(()=>Math.random()-0.5) // shuffle Arr
          setSearchDevice(ss.slice(0,50))
        }else{
          setSearchDevice(ss)
        }
        
    }else{
      let ss = [...userDevices].filter((device) => {
        if (
          device.id &&
          device.geo &&
          device.registration_number &&
          device.vehicle_model &&
          device.device_sim_number &&
          device.chasis_number &&
          device.code
        ) {
          return (
            device.id.toLowerCase().includes(search.toLowerCase()) ||
            device.registration_number
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            device.vehicle_model
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            device.device_sim_number
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            device.code
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            device.chasis_number
              .toLowerCase()
              .includes(search.toLowerCase())
          );
        }else if( 
          device.id &&
          device.geo &&
          device.registration_number &&
          device.vehicle_model &&
          device.device_sim_number &&
          device.chasis_number
          ){
            return (
              device.id.toLowerCase().includes(search.toLowerCase()) ||
              device.registration_number
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              device.vehicle_model
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              device.device_sim_number
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              device.chasis_number
                .toLowerCase()
                .includes(search.toLowerCase())
            );
        }else if( 
          device.id &&
          device.geo &&
          device.registration_number &&
          device.vehicle_model &&
          device.device_sim_number &&
          device.code){
            return (
              device.id.toLowerCase().includes(search.toLowerCase()) ||
              device.registration_number
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              device.vehicle_model
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              device.device_sim_number
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              device.code
                .toLowerCase()
                .includes(search.toLowerCase())
            );
          }else if( 
            device.id &&
            device.geo &&
            device.registration_number &&
            device.vehicle_model &&
            device.device_sim_number){
              return (
                device.id.toLowerCase().includes(search.toLowerCase()) ||
                device.registration_number
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                device.vehicle_model
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                device.device_sim_number
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            }else if( 
              device.id &&
              device.geo &&
              device.registration_number &&
              device.device_sim_number){
                return (
                  device.id.toLowerCase().includes(search.toLowerCase()) ||
                  device.registration_number
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  device.device_sim_number
                    .toLowerCase()
                    .includes(search.toLowerCase())
                );
              }else{
                return (
                  device.id && device.geo && (device.id.toLowerCase().includes(search.toLowerCase()) ||
                  device.device_sim_number
                    .toLowerCase()
                    .includes(search.toLowerCase()))
                );
              }
      })

      if(ss.length>50){
        ss.sort(()=>Math.random()-0.5) // shuffle Arr
        setSearchDevice(ss.slice(0,50))
      }else{
        setSearchDevice(ss);
      }
          
    }
  },[search])

  const handleChange = (e) => {
    const val = e.target.value;
    const dd = debounce(() => setSearch(val), 50);
    dd();
  };

  const averageGeolocation = (devices) => {
    if (devices.length === 1) {
      return { lat: devices[0].geo.lat, lng: devices[0].geo.lng };
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let dev of devices) {
      let latitude = (dev.geo.lat * Math.PI) / 180;
      let longitude = (dev.geo.lng * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }

    let total = devices.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      lat: (centralLatitude * 180) / Math.PI,
      lng: (centralLongitude * 180) / Math.PI,
    };
  };

  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
      <GoogleMap  mapContainerStyle={containerStyle} center={center} zoom={searchDevice.length>1?12:15}>
        <TrafficLayer />


        {searchDevice.map((device)=>{
          return device && device.id && device.geo? <ListMarker key={device.id} device={device} setInfoData={setInfoData}/>:null
            })}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={overlayStyle}
        >
          <Box display="flex" style={{ paddingTop: 8 }}>
            <TextField
              placeholder="Search"
              onChange={handleChange}
              style={{ width: 600, backgroundColor: "white" }}
              variant="outlined"
              name="search"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      
        {infoData && <ListInfoWindow infoData={infoData} setInfoData={setInfoData}/>}
      </GoogleMap>
    </LoadScript>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(withDevice(AllVehicleMap));
