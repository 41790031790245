import {
  USER_TRANSACTION_HEADS,
  ADD_USER_TRANSACTION_HEADS
} from "../utils/types";

const userHeadsReducer = (state = [], action) => {
  if (action.type === USER_TRANSACTION_HEADS) {
    return action.payload;
  } else if (action.type === ADD_USER_TRANSACTION_HEADS) {
    return [...state, action.payload];
  } else {
    return state;
  }
};

export default userHeadsReducer;
