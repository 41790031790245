import { combineReducers } from "redux";
import userReducer from "./user_reducer";
import devicesReducer from "./devices_reducers";
import sharedUsersReducer from "./shared_users_reducers";
import sharedDevicesReducer from "./shared_devices";
import authUserReducers from "./auth_user_reducer";
import alertReducers from "./alert_reducers";
import userDevicesReducers from "./user_devices_reducers";
import userHeadReducers from "./head_reducers";
import transactionReducers from "./transaction_reducers";

export default combineReducers({
  users: userReducer,
  authUser: authUserReducers,
  sharedUsers: sharedUsersReducer,
  sharedDevices: sharedDevicesReducer,
  userAlerts: alertReducers,
  devices: devicesReducer,
  userDevices: userDevicesReducers,
  transactionHeads: userHeadReducers,
  userTransactions: transactionReducers
});
