export const REFERENCE_LIST = [
  "",
  "Others",
  "Mahindra-Karnaphuli",
  "Abid",
  "Enam Mama",
  "Rangs Motors",
  "Rangpur Office",
  "Dhaka Office",
  "Al Adil Corporation",
  "Asad Vai"
];
export const DEVICE_TYPE_LIST = ["GT06", "WeTrack Two", "SinoTrack","S102A","S06A","S5E","R12L","TK309","QS109"];
export const VEHICLE_TYPE = [
  { name: "Car", value: 1 },
  { name: "Bike", value: 2 },
  { name: "Micro-Bus", value: 3 },
  { name: "Bus", value: 4 },
  { name: "Truck", value: 5 },
  { name: "CNG", value: 6 },
  { name: "Ship", value: 7 },
  { name: "Tractor", value: 8 },
  { name: "Others", value: 9 },
];

const workshops =[
  "BOGURA WORKSHOP",
  "CHITTAGONG WORKSHOP",
  "JASHORE WORKSHOP",
  "MYMENSINGH WORKSHOP",
  "GAZIPUR WORKSHOP",
  "TANGAIL WORKSHOP",
  "RANGPUR WORKSHOP",
  "SYLHET WORKSHOP",
  "SONARGAON WORKSHOP",
  "TEJGAON WORKSHOP",
  "CUMILLA WORKSHOP",
  "HOBIGANJ WORKSHOP",
  "THAKURGAON WORKSHOP",
  "BARISHAL WORKSHOP",
  "MANIKGANJ WORKSHOP",
  "SATKHIRA WORKSHOP",
  "FARIDPUR WORKSHOP",
  "DINAJPUR WORKSHOP",
  "SIRAJGANJ WORKSHOP",
  "KUSHTIA WORKSHOP",
  "KHULNA WORKSHOP",
  "OTHERS",
  "BIRULIA",
  "JATRABARI",
  "NARSINGDI",
  "MIRPUR-1",
  "HEAD OFFICE",
  "",
  ]
  
export const get_workshops=()=>{
  return workshops.sort()
}

export const MAP_API_KEY= "AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ"







