/* global google */
import React, { useEffect, useState } from "react";
import { Box, Card, Typography ,CircularProgress} from "@material-ui/core";

import axios from "axios";

import { withDevice } from "../device";

import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
  Circle
} from "@react-google-maps/api";
import { MAP_API_KEY } from "../../../utils/web_utils";
import DailyPolyline from "../component/daily-report/daily_polyline";

import { DAILY_LOCATIONS_URL } from "../../../utils/url";

import marker_blue from "../../../images/marker_blue.png";
import marker_red from "../../../images/marker_red.png";
import { getDistanceFromLocations } from "./daily-report/utils";
import TripPopup from "./daily-report/trip-popup";
import MyDatePicker from "./daily-report/date_picker";
import UserDeviceAutoComplete from "./daily-report/user_device_autocomplete";
import SharedUserDeviceAutoComplete from "./daily-report/shared_user_devices_autocomplete";


const bb = {
  left: 0,
  top: 0,
  zIndex: 20000,
  padding: 10,
};

let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};

const containerStyle = {
  height: "100%",
  width: "100%",
};

const DailyTravelReport = ({ deviceParam }) => {
  const { setTitle,fromSharedUser } = deviceParam;

  const [state, setState] = useState({
    selectedDate: new Date(),
    device: { ...deviceParam.device },
    distance: 0,
    center: { ...defaultLatLng },
    locations: [],
    location: null,
    progressVisible:false
  });

  const setSelectedDate = (date) => {
    let oldState = { ...state };
    oldState.selectedDate = date;
    setState(oldState);
  };

  const setDevice = (device) => {
    let oldState = { ...state };
    oldState.device = device;
    setState(oldState);
  };

  const setLocation = (location) => {
    let oldState = { ...state };
    oldState.location = location;
    setState(oldState);
  };

  const getOtherLocations = () => {
    let ll = [...state.locations].slice(1, -1);
    return ll
  };

  useEffect(() => {
    setTitle("Daily Travel Report of " + state.device.registration_number);
  }, [state.device]);

  useEffect(() => {
    if (state.device && state.selectedDate) {
      let postData = {
        device_id: state.device.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        day: state.selectedDate.getDate(),
        device_type: state.device.vehicle_type,
      };
      setState(old=>({...old,progressVisible:true}))
      axios
        .post(DAILY_LOCATIONS_URL, postData)
        .then((response) => {
          let oldState = { ...state };
          oldState.locations = response.data;
          oldState.progressVisible=false
          setState(oldState);
        })
        .catch((err) => console.log(err));
      //   axios.post(DAILY_LOCATIONS_URL,{device_time:selectedDate,id:device.id,device_type:device.vehicle_type})
      //     .then(response=>setLocations(response.data))
      //     .catch(err=>console.log(err))
    }
  }, [state.device, state.selectedDate]);

  useEffect(() => {
    

    ///Original Code

    let distance = getDistanceFromLocations(state.locations)
    let oldState = {...state}
    oldState.distance = distance



    if (state.locations.length > 0) {
      // let cen = getCenter([...state.locations].map(x=>({latitude:x.geo.lat,longitude:x.geo.lng})))
      
        oldState.center ={lat:state.locations[0].geo.lat,lng:state.locations[0].geo.lng}
    } else {
        oldState.center ={...defaultLatLng}
    }

    setState(oldState)

  }, [state.locations]);
  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
      {state.center && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={state.center}
          zoom={18}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={bb}
          >
            <MyDatePicker
              selectedDate={state.selectedDate}
              setSelectedDate={setSelectedDate}
            />

            <Card style={{ zIndex: 30000 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 300,
                  height: 48,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {state.progressVisible?<CircularProgress color="inherit" />: <Typography variant="button">
                  Travel Distance {state.distance} KM
                </Typography>}
               
              </Box>
            </Card>

            {fromSharedUser?<SharedUserDeviceAutoComplete setDevice={setDevice} device={state.device}/>:<UserDeviceAutoComplete
                  setDevice={setDevice}
                  device={state.device}
                />}
            {/* <Button onClick={animate}>Animate</Button> */}
          </Box>
          {state.locations.length > 0 && (
            <div>
              
              <Marker
                onClick={() => setLocation(state.locations[0])}
                title="Start Position"
              
                icon={{
                  url: marker_red,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                position={{
                  lat: state.locations[0].geo.lat,
                  lng: state.locations[0].geo.lng,
                }}
              />
              {state.locations.length > 1 && (
                <Marker
                  title="End Position"
                  onClick={() =>
                    setLocation(state.locations[state.locations.length - 1])
                  }
                  icon={{
                    url: marker_blue,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  position={{
                    lat: state.locations[state.locations.length - 1].geo.lat,
                    lng: state.locations[state.locations.length - 1].geo.lng,
                  }}
                />
              )}

              {getOtherLocations().map((loc) => (

                  <Circle
                    onClick={() => setLocation(loc)}
                    key={loc._id}
                    center={{lat:loc.geo.lat,lng:loc.geo.lng}}
                    radius={10}
                    options={{strokeColor: "#00ff00",fillColor:"#00ff00",fillOpacity:1}}
                    />
              ))}

<DailyPolyline locations={state.locations} />
            </div>
          )}

          {state.location && (
            <InfoWindow
              position={{
                lat: state.location.geo.lat,
                lng: state.location.geo.lng,
              }}
              onCloseClick={() => setLocation(null)}
            >
              <TripPopup location={state.location} />
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default withDevice(DailyTravelReport);
