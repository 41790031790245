import React, { useState, useEffect } from "react";
import { withDevice } from '../device'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import MyDatePicker from './daily-report/date_picker'
import SharedUserDevicesAutocomplete from './shared_user_device_autocomplete'
import { Box, Typography, Card } from '@material-ui/core'
import { DAILY_REPORT_URL } from '../../../utils/url'
import axios from 'axios'
import distanceIcon from '../../../images/distance.png'
import timeIcon from '../../../images/time.png'


const HourlyReport = ({ hourly_report }) => {

    // console.log(hourly_report,"Youu")
    const hourValueGetter = params => params.data._id+1


    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: "100%", minWidth: 600 }}>
            <AgGridReact
                rowData={hourly_report}>
                <AgGridColumn width={100} headerName="Hours" valueGetter={hourValueGetter} field="_id"></AgGridColumn>
                <AgGridColumn width={150} field="start"></AgGridColumn>
                <AgGridColumn width={150} field="end"></AgGridColumn>
                <AgGridColumn width={200} headerName="Distance (KM)" field="distance"></AgGridColumn>
            </AgGridReact>
        </div>

    )
}


const TripReport = ({ trip_report }) => {


    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: "100%", minWidth: 600 }}>
            <AgGridReact
                rowData={trip_report}>
                <AgGridColumn width={100} field="status"></AgGridColumn>
                <AgGridColumn width={100} field="start"></AgGridColumn>
                <AgGridColumn width={100} field="end"></AgGridColumn>
                <AgGridColumn width={150} field="duration"></AgGridColumn>
                <AgGridColumn width={150} headerName="Distance (KM)" field="distance"></AgGridColumn>
            </AgGridReact>
        </div>
    )

}



const SharedDevicesDailyReport =({deviceParam})=>{

    const { setTitle,device } = deviceParam;

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [currentDevice, setCurrentDevice] = useState({...device})
    const [report, setReport] = useState(null)


    useEffect(() => {
        setTitle("Shared Devices Daily Report")
    }, [])


    useEffect(() => {

        if (selectedDate && currentDevice) {
            getDailyReportData()
        }

    }, [selectedDate, currentDevice])


    const getRunningTime = time=>{
        let hour = Math.floor(time/3600)
        let rem = time -3600*hour
        let min = Math.floor(rem/60)

        return hour+" hrs "+min+" mins"
    }


    const getDailyReportData = () => {

        let data = {
            "year": selectedDate.getFullYear(),
            "month": selectedDate.getMonth(),
            "day": selectedDate.getDate(),
            "device_type": currentDevice.vehicle_type,
            "device_id": currentDevice.id
        }




        axios.post(DAILY_REPORT_URL, data)
            .then(response => {
                setReport(response.data)
            }).catch(err => {
                console.log(err)
                setReport(null)
                
            })

    }

    return(
        <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ padding: 10 }}>
                <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />


                {report && <Card style={{ height: 80, backgroundColor: "#eeeeee" }}>
                    <Box display="flex" flexDirection="column">
                        <img src={distanceIcon} style={{ height: 50 }} />
                    </Box>

                    <Box display="flex" style={{ width: "100%" ,padding:6 }} justifyContent="center" alignItems="center">

                        <Typography variant="body1">{report.total_distance} KM</Typography>

                    </Box>

                </Card>}

                {report && <Card style={{ height: 80, backgroundColor: "#eeeeee" }}>
                    <Box display="flex" flexDirection="column">
                        <img src={timeIcon} style={{ height: 50 }} />
                    </Box>

                    <Box display="flex" style={{ width: "100%",padding:6 }} justifyContent="center">

                        <Typography variant="body1">{getRunningTime(report.running_time)}</Typography>

                    </Box>



                </Card>}

                <SharedUserDevicesAutocomplete setDevice={setCurrentDevice} />
            </Box>


            {report && (<Box display="flex" justifyContent="space-between" alignItems="center" style={{ paddingLeft: 10, paddingRight: 10 }}>

                <Box disply="flex" flexDirection="column" flexGrow="1">

                    <Typography variant="h6" style={{ textAlign: "center", backgroundColor: "#eeeeee" }}>Hourly Report</Typography>
                    <HourlyReport hourly_report={report.hourly_report} />
                </Box>

                <Box display="flex" style={{ width: 100 }} />

                <Box disply="flex" flexDirection="column" flexGrow="1">
                    <Typography variant="h6" style={{ textAlign: "center", backgroundColor: "#eeeeee" }}>Trip Report</Typography>
                    <TripReport trip_report={report.trip_report} />
                </Box>
            </Box>)}





        </Box>
    )

}

export default withDevice(SharedDevicesDailyReport)