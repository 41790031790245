import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addDevice } from '../../actions';

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Select,
  InputLabel,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  REFERENCE_LIST,
  DEVICE_TYPE_LIST,
  VEHICLE_TYPE,
  get_workshops,
} from '../../utils/web_utils';
import { withFirebase } from '../Firebase';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const initialErrorState = { error: false, message: '', fieldId: 0 };

const initialState = {
  id: '',
  registration_number: '',
  vehicle_model: '',
  vehicle_type: 0,
  service_charge: 0,
  device_sim_number: '',
  center_number: '',
  reference: '',
  device_model: 'GT06',
  code: '',
  mask_id: '',
  over_due: false,
  chasis_number: '',
  work_shop: '',
};

const AddDevice = ({ open, setOpen, firebase, addDevice }) => {
  const [dev, setDev] = useState({ ...initialState });
  const [err, setErr] = useState(initialErrorState);
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const cDev = { ...dev };
    cDev[e.target.name] = e.target.value;

    // console.log(cDev)
    setDev(cDev);
  };

  const handleCheckChange = (event) => {
    const cDev = { ...dev };
    cDev['over_due'] = event.target.checked;
    setDev(cDev);
  };

  const handleCheckChangeSalesView = (event) => {
    const cDev = { ...dev };
    cDev['sales_view'] = event.target.checked;
    console.log(cDev);
    setDev(cDev);
  };

  const resolve = () => {
    setShow(false);
    setOpen(false);
    setDev({ ...initialState });
  };

  const handleSubmit = (e) => {
    if (dev.id === '') {
      setErr({
        error: true,
        message: 'Empty Field is not Allowed',
        fieldId: 1,
      });
    } else if (dev.registration_number === '') {
      setErr({
        error: true,
        message: 'Empty Field is not Allowed',
        fieldId: 2,
      });
    } else if (dev.vehicle_model === '') {
      setErr({
        error: true,
        message: 'Empty Field is not Allowed',
        fieldId: 3,
      });
    } else if (dev.device_sim_number === '') {
      setErr({
        error: true,
        message: 'Empty Field is not Allowed',
        fieldId: 4,
      });
    } else if (dev.center_number === '') {
      setErr({
        error: true,
        message: 'Empty Field is not Allowed',
        fieldId: 5,
      });
    } else {
      setErr(initialErrorState);
      setShow(true);
      addDevice(dev, firebase, resolve);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textTransform: 'uppercase' }}
      >
        Crete a new Device
      </DialogTitle>

      {show && (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}

      <DialogContent>
        <TextField
          autoFocus
          error={err.error && err.fieldId === 1}
          label={err.error && err.fieldId === 1 ? err.message : 'ID'}
          name="id"
          variant="outlined"
          onChange={handleChange}
          value={dev.id}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={err.error && err.fieldId === 2}
          label={
            err.error && err.fieldId === 2 ? err.message : 'Registration Number'
          }
          name="registration_number"
          variant="outlined"
          onChange={handleChange}
          value={dev.registration_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          label="Chasis Number"
          name="chasis_number"
          variant="outlined"
          onChange={handleChange}
          value={dev.chasis_number}
          fullWidth
          style={{ marginTop: 10 }}
        />

        <TextField
          error={err.error && err.fieldId === 3}
          label={err.error && err.fieldId === 3 ? err.message : 'Model'}
          name="vehicle_model"
          variant="outlined"
          onChange={handleChange}
          value={dev.vehicle_model}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          label="Code"
          name="code"
          variant="outlined"
          onChange={handleChange}
          value={dev.code}
          fullWidth
          style={{ marginTop: 10 }}
        />

        <TextField
          label="Mask ID"
          name="mask_id"
          variant="outlined"
          onChange={handleChange}
          value={dev.mask_id}
          fullWidth
          style={{ marginTop: 10 }}
        />

        <TextField
          autoFocus
          error={err.error && err.fieldId === 4}
          label={
            err.error && err.fieldId === 4 ? err.message : 'Device SIM Number'
          }
          name="device_sim_number"
          variant="outlined"
          onChange={handleChange}
          value={dev.device_sim_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          autoFocus
          error={err.error && err.fieldId === 5}
          label={err.error && err.fieldId === 5 ? err.message : 'Center Number'}
          name="center_number"
          variant="outlined"
          onChange={handleChange}
          value={dev.center_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          autoFocus
          error={err.error && err.fieldId === 6}
          label={
            err.error && err.fieldId === 6 ? err.message : 'Service Charge'
          }
          name="service_charge"
          variant="outlined"
          onChange={handleChange}
          value={dev.service_charge}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <InputLabel htmlFor="reference" style={{ marginTop: 10 }}>
          References
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={dev.reference}
          onChange={handleChange}
          inputProps={{
            name: 'reference',
            id: 'reference',
          }}
          fullWidth
        >
          {REFERENCE_LIST.map((ref, index) => (
            <option key={index} value={ref}>
              {ref}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="device_model" style={{ marginTop: 10 }}>
          Device Model
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={dev.device_model}
          onChange={handleChange}
          inputProps={{
            name: 'device_model',
            id: 'device_model',
          }}
          fullWidth
        >
          {DEVICE_TYPE_LIST.map((ref, index) => (
            <option key={index} value={ref}>
              {ref}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="vehicle_type" style={{ marginTop: 10 }}>
          Vehicle Type
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={dev.vehicle_type}
          onChange={handleChange}
          inputProps={{
            name: 'vehicle_type',
            id: 'vehicle_type',
          }}
          fullWidth
        >
          {VEHICLE_TYPE.map((item, index) => (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="work_shop" style={{ marginTop: 10 }}>
          WORKSHOP
        </InputLabel>

        <Select
          style={{ marginTop: 10 }}
          native
          value={dev.work_shop}
          onChange={handleChange}
          inputProps={{
            name: 'work_shop',
            id: 'work_shop',
          }}
          fullWidth
        >
          {get_workshops().map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </Select>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox value={dev.over_due} onChange={handleCheckChange} />
            }
            label="Overdue Payment"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={dev.sales_view}
                onChange={handleCheckChangeSalesView}
              />
            }
            label="Sales View"
          />
        </div>

        {/* <Checkbox label="Overdue Payment" value={dev.over_due} onChange={handleChange} /> */}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDevice: (device, firebase, resolve) =>
      dispatch(addDevice(device, firebase, resolve)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(AddDevice));
