import React, { useState } from "react";

import { withDevice } from "../web/device";
import {
  Slide,
  Dialog,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { deleteUser } from "../../actions";
import { withFirebase } from "../Firebase";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteUser = ({ deviceParam, open, setOpen, firebase, deleteUser }) => {
  const { user, setUser } = deviceParam;
  const [show, setShow] = useState(false);

  const resolve = () => {
    setUser(null);
    setShow(false);
    setOpen(false);
  };

  const deleteClick = (e) => {
    setShow(true);
    deleteUser(user, firebase, resolve);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Box display="flex" flexDirection="column" style={{ width: 500 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Typography variant="h5">Delete User??</Typography>
          <Typography variant="button" style={{ marginTop: 10 }}>
            Do you really want to delete {user && user.name}
          </Typography>
        </Box>

        {show && (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="secondary" />
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" style={{ padding: 10 }}>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={deleteClick}>
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (user, firebase, resolve) =>
      dispatch(deleteUser(user, firebase, resolve)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDevice(withFirebase(DeleteUser)));
