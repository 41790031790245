import { FETCH_SHRED_DEVICES, DELETE_USER_SHRED_DEVICE } from "../utils/types";

const sharedDevicesReducer = (state = [], action) => {
  if (action.type === FETCH_SHRED_DEVICES) {
    return action.payload;
  } else if (action.type === DELETE_USER_SHRED_DEVICE) {
    let sharedDevices = [...state].filter(
      device => device._id !== action.payload._id
    );
    return sharedDevices;
  } else {
    return state;
  }
};

export default sharedDevicesReducer;
