import {
  USER_TRANSACTIONS,
  ADD_USER_TRANSACTIONS,
  UPDATE_USER_TRANSACTION,
  DELETE_USER_TRANSACTION
} from "../utils/types";

const transactionReducers = (state = [], action) => {
  if (action.type === USER_TRANSACTIONS) {
    return action.payload;
  } else if (action.type === ADD_USER_TRANSACTIONS) {
    return [...state, action.payload];
  } else if (action.type === UPDATE_USER_TRANSACTION) {
    let transactions = [...state];
    transactions[
      transactions.indexOf(
        transactions.filter(item => item._id === action.payload._id)[0]
      )
    ] = action.payload;

    return transactions;
  } else if (action.type === DELETE_USER_TRANSACTION) {
    let transactions = [...state].filter(
      transaction => transaction._id !== action.payload._id
    );
    return transactions;
  } else {
    return state;
  }
};

export default transactionReducers;
