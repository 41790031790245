import React, { useState, useEffect } from "react";

import { withFirebase } from "../Firebase";

import { LANDING } from "../../constants/routes";
import { getAuthUser } from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import WebAppBar from "./component/web_app_bar";
import WebDrawer from "./component/web_drawer";
import DeviceList from "./component/device_list";
import MapAllVehicle from "./component/all_vehicle_map";
import SingleMap from "./component/single_map";
import SharedDevices from "./component/shared_devices";
import Profile from "./component/profile";
import Notifications from "./component/notifications";
import Alerts from "./component/alerts";
import Expenses from "./component/expenses";
import UnAssignDevices from "./component/unassign";
import MonthlyReport from "./component/report";
import WorkshopReport from './component/work-shop-report'

import MonthlyInactiveDevices from './component/monthly_inactive_devices'
import MonthlyReportAll from './component/monthly_report'
import MonthlyTemperatureLog from "./component/monthly_temperature_log";
import SharedDevicesDailyReport from './component/share_device_daily_report'
import { DeviceContext } from "./device";
import { Box ,Paper} from "@material-ui/core";

import posed, { PoseGroup } from "react-pose";
import DailyTemperatureLog from "./component/daily_temperature_log";
import MonthlyPTIOReport from "./component/monthly_ptio_report";
import DailyTravelReport from "./component/daily_travel_report";
import DailySpeedReport from "./component/daily_speed_report";
import DailyReportSingle from "./component/daily-report-single";



const pages = [
  (props) => <DeviceList {...props} />,
  (props) => <MapAllVehicle {...props} />,
  (props) => <SharedDevices {...props} />,
  (props) => <Profile {...props} />,
  (props) => <Notifications {...props} />,
  (props) => <Alerts {...props} />,
  (props) => <Expenses {...props} />,
  (props) => <UnAssignDevices {...props} />,
  // (props) => <DailyReport {...props} />,
  (props) => <MonthlyInactiveDevices {...props} />,
  (props) => <MonthlyReportAll {...props} />,
  (props) => <WorkshopReport {...props} />,
  // (props) => <DailyTemperatureLog {...props} />,
  (props) => <SingleMap {...props} />,
  (props) => <MonthlyReport {...props} />,
  // (props) => <SharedDevicesDailyReport {...props} />,
  (props) => <MonthlyTemperatureLog {...props} />,
  (props) => <MonthlyPTIOReport {...props} />,
  (props) => <DailyTravelReport {...props} />,
  (props) => <DailySpeedReport {...props} />,
  (props) => <DailyReportSingle {...props} />,
  
];

const MyPage = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      // y: { type: "spring", stiffness: 1000, damping: 15 },
      // default: { duration: 300 },
      duration: 500,
    },
  },
  exit: {
    y: -100,
    x: "-100%",
    delay: 100,
    opacity: 0,
    transition: { duration: 500 },
  },
});

const Web = (props) => {

  const [state,setState]= useState({
    drawerOpen:false,
    currentPage:0,
    title:"",
    components:[0],
    device:null,
    fromSharedUser:false
  })

  const stopListener = (listener) => {

    listener();
  };

  const menuClick = () => {
    
    setState(old=>({...old,drawerOpen:!old.drawerOpen}))
  };

  const closeDrawer = () => {
    setState(old=>({...state,drawerOpen:false}))
  };

  const backClick = () => {
    let components = [...state.components];
    components.pop();
    setState(old=>({...old,components:components}))
  };

  const handleDrawerItemClick = (index) => {

    const jjj = [...state.components, index];
    setState(old=>({...old,components:jjj}))
  };

  const handleMapClick = (device) => {
    if (device.geo) {
      const jjj = [...state.components, 11];
      setState(old=>({...old,components:jjj,device:device}))
   
    } else {
      const ref = props.firebase.singleDeviceRef(device.id).child("geo");
      ref.on("value", (snapshop) => {
        ref.off();
        if (snapshop.val()) {
          device["geo"] = snapshop.val();
          const jjj = [...state.components, 11];
          setState(old=>({...old,components:jjj,device:device}))
          
        }
      });
    }
  };

  const reportClick = (device,fromSharedUser) => {
    const jjj = [...state.components, 12];
    setState(old=>({...old,components:jjj,device:device,fromSharedUser:fromSharedUser}))
  };

  // const sharedDevicesDailyReport = (device)=>{
  //   const jjj = [...state.components, 13];
  //   setState(old=>({...old,components:jjj,device:device}))
  // }

  const monthlyTemperatureLog = (device)=>{
    const jjj = [...state.components, 13];
    setState(old=>({...old,components:jjj,device:device}))

  }

  const monthlyPTIOReport= (device)=>{
    const jjj = [...state.components, 14];
    setState(old=>({...old,components:jjj,device:device}))

  }

  const dailyTravelReportClick= (device,fromSharedUser)=>{
    const jjj = [...state.components, 15];
    setState(old=>({...old,components:jjj,device:device,fromSharedUser:fromSharedUser}))
  }

  const dailySpeedReportClick= (device,fromSharedUser)=>{
    const jjj = [...state.components, 16];
    setState(old=>({...old,components:jjj,device:device,fromSharedUser:fromSharedUser}))
  }

  const dailyReportClick= (device,fromSharedUser)=>{
    const jjj = [...state.components, 17];
    setState(old=>({...old,components:jjj,device:device,fromSharedUser:fromSharedUser}))
  }

  const setDevice=dev=>{
    setState(old=>({...old,device:dev}))
  }

  const setTitle=title=>{
    setState(old=>({...old,title:title}))
  }


  useEffect(() => {
    const listener = props.firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          props.getAuthUser(user, props.firebase);
        } else {
        }
      } else {
        props.getAuthUser(null, props.firebase);
        props.history.push(LANDING);
      }
    });

    return () => stopListener(listener);
  }, []);

  const renderPage = () => {
    const index = state.components[state.components.length - 1];
    const Page = pages[index];

    return <Page />;
  };

  return (
    <Paper>
      <Box
      display="flex"
      flexDirection="column"
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <WebAppBar
        menuClick={menuClick}
        components={state.components}
        backClick={backClick}
        title={state.title}
      />

      <Box display="flex" flexGrow="1">
        <DeviceContext.Provider
          value={{
            device: state.device,
            setDevice: setDevice,
            title: state.title,
            setTitle: setTitle,
            handleMapClick: handleMapClick,
            reportClick: reportClick,
            monthlyTemperatureLog:monthlyTemperatureLog,
            monthlyPTIOReport:monthlyPTIOReport,
            dailyTravelReportClick:dailyTravelReportClick,
            dailySpeedReportClick:dailySpeedReportClick,
            dailyReportClick:dailyReportClick,
            fromSharedUser:state.fromSharedUser
          }}
        >
          {props.authUser ? (
            <PoseGroup>
              <MyPage
                key={state.components[state.components.length - 1]}
                style={{ width: "100%" }}
              >
                {renderPage()}
              </MyPage>
            </PoseGroup>
          ) : null}
        </DeviceContext.Provider>
      </Box>

      <WebDrawer
        open={state.drawerOpen}
        handleClose={closeDrawer}
        authUser={props.authUser}
        handleItemClick={handleDrawerItemClick}
        selected={state.currentPage}
        components={state.components}
      />
    </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthUser: (firebaseUser, firebase) =>
      dispatch(getAuthUser(firebaseUser, firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withFirebase(Web)));

