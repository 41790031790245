import React from "react";
import { Polyline } from "@react-google-maps/api";





  const DailyPolyline = ({locations})=>{

    const options = {
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#555555',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: 1
      };


    const getPath=()=>{
        let path = [...locations].map(x=>{
            return {lat:x.geo.lat,lng:x.geo.lng}
        })

        return path
    }


    return(
        <Polyline
        path={getPath()}
        options={options}
        />
    )


  }

  export default DailyPolyline;