import {
  Box,
  Card,
  Divider,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";

import { withDevice } from "../web/device";
import NotificationUsers from "./notification_user_list";
import axios from "axios";
import { NOTIFICATION } from "../../utils/url";

const initialErrorState = { error: false, message: "", fieldId: 0 };
const initialState = { title: "", message: "" };

const NotificationPage = ({ deviceParam }) => {
  const { setTitle } = deviceParam;

  const [selected, setSelected] = useState([]);
  const [notification, setNotification] = useState({ ...initialState });
  const [err, setErr] = useState(initialErrorState);

  const [disable, setDisable] = useState(true);

  const handleCheckChange = (state, user) => {
    if (state) {
      setSelected([...selected, user]);
    } else {
      setSelected([...selected].filter((usr) => usr._id != user._id));
    }
  };

  const contains = (user) => {
    if ([...selected].filter((al) => al._id === user._id).length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setTitle("Notifications Page");
  }, []);

  useEffect(() => {
    if (
      notification.title !== "" &&
      notification.message !== "" &&
      selected.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [notification, selected]);

  const handleChange = (e) => {
    const not = { ...notification };
    not[e.target.name] = e.target.value;
    setNotification({ ...not });
  };

  const submitClick = () => {
    console.log(selected);

    const uuu = [];
    selected.map((u) => {
      const x = {};
      x["_id"] = u._id;
      x["token"] = u.token;
      uuu.push(x);
    });

    notification["users"] = uuu;

    axios
      .post(NOTIFICATION, notification)
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err));

    console.log(notification);
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: grey[700],
      }}
    >
      <Box display="flex" flexGrow="1" style={{ padding: 10 }}>
        <Card square style={{ width: "100%", height: "100%" }}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" style={{ padding: 10 }}>
              <Typography style={{ textTransform: "uppercase" }}>
                Send Notification To Any User
              </Typography>
            </Box>

            <Divider />

            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ padding: 10 }}
            >
              <Typography style={{ textTransform: "uppercase" }}>
                Selected Users ( {selected.length} )
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" style={{ padding: 10 }}>
              <TextField
                error={err.error && err.fieldId === 1}
                label={err.error && err.fieldId === 1 ? err.message : "Title"}
                name="title"
                variant="outlined"
                onChange={handleChange}
                value={notification.title}
                fullWidth
                style={{ marginTop: 10 }}
                required
              />

              <TextField
                error={err.error && err.fieldId === 2}
                label={err.error && err.fieldId === 2 ? err.message : "Message"}
                name="message"
                variant="outlined"
                onChange={handleChange}
                value={notification.message}
                fullWidth
                style={{ marginTop: 10 }}
                required
              />

              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ marginTop: 10 }}
              >
                <Button
                  color="secondary"
                  disabled={disable}
                  onClick={submitClick}
                >
                  submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>

      <Box display="flex" flexGrow="1" style={{ padding: 10, maxWidth: "50%" }}>
        <Card square style={{ width: "100%", height: "100%" }}>
          <NotificationUsers
            handleCheckChange={handleCheckChange}
            contains={contains}
          />
        </Card>
      </Box>
      {/* <Card style={{ width: "48%", height: "100%" }}></Card>
      <Card style={{ width: "48%", height: "100%" }}></Card> */}
    </Box>
  );
};

export default withDevice(NotificationPage);
