import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withFirebase } from "../../Firebase";
import { updateUserDevice } from "../../../actions/user_devices_action";

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Avatar,
  IconButton,
  Card,
  CircularProgress,
} from "@material-ui/core";

import { PhotoCamera } from "@material-ui/icons";
import { indigo } from "@material-ui/core/colors";
import { withDevice } from "../device";





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const initialErrorState = { error: false, message: "", fieldId: 0 };

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  card: {
    backgroundColor: theme.palette.primary,
  },
}));

const EditDevice = ({
  deviceParam,
  open,
  setOpen,
  updateUserDevice,
  firebase,
}) => {
  const { device, setDevice } = deviceParam;
  const [dev, setDev] = useState({ ...device });
  const [err, setErr] = useState(initialErrorState);
  const [file, setFile] = useState();
  const [show, setShow] = useState(false);

  const classes = useStyles();

  const fileSelect = (e) => {
    let device = { ...dev };
    device.driver_photo = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setDev(device);
  };

  const handleSave = (e) => {

    if (dev.registration_number === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 3,
      });
    } else if (dev.vehicle_model === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 4,
      });
    } else if (dev.device_sim_number === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 5,
      });
    } else if (
      !Number(dev.device_sim_number) ||
      dev.device_sim_number.length !== 11
    ) {
      setErr({
        error: true,
        message: "Enter a valid 11 Digit Phone Number",
        fieldId: 5,
      });
    } else {
      setErr(initialErrorState);

      const formData = new FormData();

      const {
        driver_name,
        driver_phone,
        id,
        device_sim_number,
        vehicle_model,
        registration_number,
        mileage,
        congestion_consumption,
        code,
        chasis_number
      } = dev;

      formData.append("driver_name", driver_name);
      formData.append("driver_phone", driver_phone);
      formData.append("device_sim_number", device_sim_number);
      formData.append("registration_number", registration_number);
      formData.append("vehicle_model", vehicle_model);
      formData.append("id", id);

      if (mileage) {
        formData.append("mileage", mileage);
      }

      if (code) {
        formData.append("code", code);
      }

      if (chasis_number) {
        formData.append("chasis_number", chasis_number);
      }

      if (congestion_consumption) {
        formData.append("congestion_consumption", congestion_consumption);
      }

      if (file) {
        formData.append("image", file);
      }


      updateUserDevice(firebase, device, formData, setShow, setOpen, setFile);
    }
  };

  const handleChange = (e) => {
    const cDev = { ...dev };
    cDev[e.target.name] = e.target.value;
    setDev(cDev);
  };

  useEffect(() => {
    setDev(device);
  }, [device]);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textTransform: "uppercase" }}
      >
        Edit Vehicle {device && device.id}
      </DialogTitle>

      {show && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {dev && (
        <DialogContent>
          <DialogContentText style={{ textTransform: "uppercase" }}>
            To edit your <b>Vehicle</b> fill up the Following Form Properly
          </DialogContentText>

          <TextField
            error={err.error && err.fieldId === 1}
            label={err.error && err.fieldId === 1 ? err.message : "Driver Name/Customer Name"}
            name="driver_name"
            variant="outlined"
            onChange={handleChange}
            value={dev.driver_name}
            fullWidth
            //className={classes.textField}
            style={{ marginTop: 10 }}
            required
          />

          <TextField
            error={err.error && err.fieldId === 2}
            label={
              err.error && err.fieldId === 2 ? err.message : "Driver Phone/ Customer Phone"
            }
            name="driver_phone"
            variant="outlined"
            onChange={handleChange}
            value={dev.driver_phone}
            fullWidth
            //className={classes.textField}
            style={{ marginTop: 10 }}
            required
          />

          <TextField
            error={err.error && err.fieldId === 3}
            label={
              err.error && err.fieldId === 3
                ? err.message
                : "Registration Number"
            }
            name="registration_number"
            variant="outlined"
            onChange={handleChange}
            value={dev.registration_number}
            style={{ marginTop: 10 }}
            fullWidth
            //className={classes.textField}
            required
          />

          <TextField
            label="Chasis No"
            name="chasis_number"
            variant="outlined"
            onChange={handleChange}
            value={dev.chasis_number}
            fullWidth
            style={{ marginTop: 10 }}

          />

          <TextField
            error={err.error && err.fieldId === 4}
            label={
              err.error && err.fieldId === 4 ? err.message : "Vehicle Model"
            }
            name="vehicle_model"
            variant="outlined"
            onChange={handleChange}
            value={dev.vehicle_model}
            style={{ marginTop: 10 }}
            fullWidth
            //className={classes.textField}
            required
          />

          <TextField
            label="Code"
            name="code"
            variant="outlined"
            onChange={handleChange}
            value={dev.code}
            fullWidth
            style={{ marginTop: 10 }}

          />

          <TextField
            error={err.error && err.fieldId === 5}
            label={
              err.error && err.fieldId === 5 ? err.message : "Device SIM Number"
            }
            name="device_sim_number"
            variant="outlined"
            onChange={handleChange}
            value={dev.device_sim_number}
            style={{ marginTop: 10 }}
            fullWidth
            //className={classes.textField}
            required
          />

          <TextField
            error={err.error && err.fieldId === 6}
            label={err.error && err.fieldId === 6 ? err.message : "Milage"}
            name="mileage"
            variant="outlined"
            onChange={handleChange}
            value={dev.mileage}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <TextField
            error={err.error && err.fieldId === 7}
            label={
              err.error && err.fieldId === 7
                ? err.message
                : "Congestion Consumption"
            }
            name="congestion_consumption"
            variant="outlined"
            onChange={handleChange}
            value={dev.congestion_consumption}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            style={{ padding: 10 }}
          >
            <Card raised>
              <Avatar
                variant="square"
                src={dev.driver_photo}
                alt="driver_photo"
                style={{ width: 120, height: 120 }}
              />
            </Card>

            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ width: 160, height: 50, marginTop: -25 }}
            >
              <Card raised style={{ zIndex: 10, backgroundColor: indigo[500] }}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  onChange={fileSelect}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="secondary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Card>
            </Box>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDevice: (firebase, device, formData, setShow, setOpen, setFile) =>
      dispatch(updateUserDevice(firebase, device, formData, setShow, setOpen, setFile)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDevice(withFirebase(EditDevice)));
