import React, { useEffect } from "react";

import { withDevice } from "../web/device";

const CumstomerRequestPage = ({ deviceParam }) => {
  const { setTitle } = deviceParam;

  useEffect(() => {
    const customerTitle = ()=>{
      setTitle("Cumstomer Request")
    }
    customerTitle()
  }, []);
  return <div>CumstomerRequest Page</div>;
};

export default withDevice(CumstomerRequestPage);
