import React, { useEffect, useState } from "react";
import { withDevice } from "../device";
import {withFirebase} from '../../Firebase'
import { connect } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Avatar,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import { grey, indigo } from "@material-ui/core/colors";
import { PhotoCamera } from "@material-ui/icons";
import MySnacbar from "./my_snackbar";
import {updateAuthUser} from '../../../actions'

const initialErrorState = { error: false, message: "", fieldId: 0 };

const Profile = ({ deviceParam, authUser,firebase,updateAuthUser }) => {
  const { setTitle } = deviceParam;
  const [user, setUser] = useState({ ...authUser });
  const [err, setErr] = useState(initialErrorState);
  const [file, setFile] = useState(null);
  const [snacState, setSnacState] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    setTitle("Profile of " + authUser.name);
  }, []);

  const handleChange = (e) => {
    const cUser = { ...user };
    cUser[e.target.name] = e.target.value;
    setUser(cUser);
  };

  const fileSelect = (e) => {
    let userx = { ...user };
    userx.image = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setUser(userx);
  };

  const handleSave = (e) => {
    if (user.name === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 1,
      });
    } else if (user.email === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 2,
      });
    } else if (user.contact === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 3,
      });
    } else if (user.organization_name === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 4,
      });
    } else if (user.address === "") {
      setErr({
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 5,
      });
    } else {
      setErr(initialErrorState);

      const formData = new FormData();

      const { name, email, contact, organization_name, address } = user;

      formData.append("name", name);
      formData.append("email", email);
      formData.append("contact", contact);
      formData.append("organization_name", organization_name);
      formData.append("address", address);
      formData.append("image", file);

      updateAuthUser(authUser,formData,firebase,setFile,setSnacState)
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: grey[300],
        padding: 10,
      }}
    >
      <Card style={{ width: "45%", height: "100%", padding: 20 }}>
        <Box display="flex" flexDirection="column">
          <TextField
            error={err.error && err.fieldId === 1}
            label={err.error && err.fieldId === 1 ? err.message : "Name"}
            name="name"
            variant="outlined"
            onChange={handleChange}
            value={user.name}
            fullWidth
            style={{ marginTop: 10 }}
            required
          />
          <TextField
            error={err.error && err.fieldId === 2}
            label={err.error && err.fieldId === 2 ? err.message : "Email"}
            name="email"
            variant="outlined"
            onChange={handleChange}
            value={user.email}
            fullWidth
            style={{ marginTop: 10 }}
            disabled
          />
          <TextField
            error={err.error && err.fieldId === 3}
            label={err.error && err.fieldId === 3 ? err.message : "Contact"}
            name="contact"
            variant="outlined"
            onChange={handleChange}
            value={user.contact}
            fullWidth
            style={{ marginTop: 10 }}
            required
          />

          <TextField
            error={err.error && err.fieldId === 4}
            label={
              err.error && err.fieldId === 4
                ? err.message
                : "Company/Organization"
            }
            name="organization_name"
            variant="outlined"
            onChange={handleChange}
            value={user.organization_name}
            fullWidth
            style={{ marginTop: 10 }}
            required
          />

          <TextField
            error={err.error && err.fieldId === 5}
            label={err.error && err.fieldId === 5 ? err.message : "Address"}
            name="address"
            variant="outlined"
            onChange={handleChange}
            value={user.address}
            fullWidth
            style={{ marginTop: 10 }}
            required
          />
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            style={{ padding: 10 }}
          >
            <Card raised>
              <Avatar
                variant="square"
                src={user.image}
                alt="user photo"
                style={{ width: 100, height: 100 }}
              />
            </Card>

            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ width: 140, height: 50, marginTop: -25 }}
            >
              <Card raised style={{ zIndex: 10, backgroundColor: indigo[500] }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={fileSelect}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="secondary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Card>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ marginTop: 10 }}
          >
            <Button color="secondary" onClick={handleSave}>
              Update
            </Button>
          </Box>
        </Box>
      </Card>
      <Card style={{ width: "45%", height: "100%", padding: 20 }}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              src={user.image}
              alt={user.name}
              style={{ width: 80, height: 80 }}
            />
            <Typography variant="h6">{user.name}</Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" style={{ padding: 10 }}>
            <Typography variant="body2">Contact:</Typography>
            <Typography variant="h6">{user.contact}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" style={{ padding: 10 }}>
            <Typography variant="body2">Company/Organization:</Typography>
            <Typography variant="h6">{user.organization_name}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" style={{ padding: 10 }}>
            <Typography variant="body2">Address:</Typography>
            <Typography variant="h6">{user.address}</Typography>
          </Box>
        </Box>
      </Card>
      <MySnacbar snacState={snacState} setSnacState={setSnacState} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthUser: (authUser, formData, firebase, setFile,setSnacState) => dispatch(updateAuthUser(authUser, formData, firebase, setFile,setSnacState)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withDevice(withFirebase(Profile)));
