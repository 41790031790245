import React from 'react'
import {connect} from 'react-redux'
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';


const SharedUserDeviceAutoComplete = ({sharedDevices,setDevice,device})=>{
    
    return(
        <Autocomplete
            id="combo-box-demo"
            options={[...sharedDevices].filter(dev=>dev.registration_number && dev.registration_number!=="")}
            getOptionLabel={(device) => device.registration_number}
            style={{ width: 300 }}
            value={device}
            onChange={(event,value,reason)=>value && setDevice(value)}
            renderInput={(params) => <TextField {...params} label="Select Registration Number" variant="outlined" style={{backgroundColor:"#fff"}} />}
        />
    )


}


const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };

  export default connect(
    mapStateToProps
  ) (SharedUserDeviceAutoComplete)