//const BASE_URL = "https://rangs.sultantracker.com/api/api/";
// const BASE_URL = "http://103.144.201.119:7777/api/";

// db.devices.update({},{$set: {"password" : "rangs1234"}},false,true)
// db.devices.updateOne({code:"MP22BigBV19153"},{$set:{"password":"RMLVTS123"}})

// const BASE_URL = "https://mongo2.sultantracker.com/api/";

// const BASE_URL = "https://karnaphuli.sultantracker.com/api/api/";

const BASE_URL = 'https://mongo4.sultantracker.com/api/';
// const BASE_URL = 'http://localhost:5001/api/';

export const USERS_URL = BASE_URL + 'users/';
export const USERS_GET_BY_ID = USERS_URL + 'getbyid/';
export const DEVICES_URL = BASE_URL + 'devices/';
export const REFERENCE_UNASSIGN_URL = BASE_URL + 'devices/references/';
export const COMMAND_URL = BASE_URL + 'commands/';
export const SHARED_DEVICE = BASE_URL + 'shareddevices/';
export const SHARED_USERS = SHARED_DEVICE + 'sharedusers/';
export const USER_SHARED_DEVICE = SHARED_DEVICE + 'shareddevices/';
export const ALERTS = BASE_URL + 'alerts/';
export const NOTIFICATION = BASE_URL + 'notifications/';
export const DELETE_MANY_ALERTS = ALERTS + 'deleteMany/';
export const HEADS_URL = BASE_URL + 'heads/';
export const TRANSACTIONS_URL = BASE_URL + 'transactions/';
export const LOCATIONS_URL = BASE_URL + 'locations/';
export const MONTHLY_LOCATIONS_URL = LOCATIONS_URL + 'monthly/';
export const DAILY_LOCATIONS_URL = LOCATIONS_URL + 'daily-locations/';
export const DAILY_REPORT_URL = LOCATIONS_URL + 'daily-report/';
export const MONTHLY_FILE_URL = MONTHLY_LOCATIONS_URL + 'pdf/';
export const LOCATION_REPORT_URL = LOCATIONS_URL + 'locationreport/';
export const DISTANCE_REPORT_URL = LOCATIONS_URL + 'distancereport/';
export const ALL_MONTHLY_REPORT = BASE_URL + 'all-monthly-report/';

export const ALL_TEMP_REPORT = BASE_URL + 'temperatures/alldevice/';
export const ALL_TEMP_REPORT_EXCEL = BASE_URL + 'temperatures/alldevice/excel/';
export const MONTHLY_TEMP_REPORT =
  BASE_URL + 'temperatures/temperature_monthly_report/';
export const MONTHLY_TEMP_REPORT_EXCEL =
  BASE_URL + 'temperatures/temperature_monthly_report/excel/';
export const MONTHLY_PTIO_REPORT = BASE_URL + 'cpdata/pto_report/';

export const DAILY_SPEED_URL = LOCATIONS_URL + 'speed-report/';
