import { USER_ALERTS, DELETE_ALERT, DELETE_MANY_ALERT } from "../utils/types";
import { ALERTS, DELETE_MANY_ALERTS } from "../utils/url";
import axios from "axios";

const userAlertAction = (data) => {
  return { type: USER_ALERTS, payload: data };
};

const deleteAlertAction = (data) => {
  return { type: DELETE_ALERT, payload: data };
};

const deleteManyAlertAction = (data) => {
  return { type: DELETE_MANY_ALERT, payload: data };
};

export const userAlert = (webuid, firebase) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      axios
        .get(ALERTS + webuid, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(userAlertAction(response.data));
        })
        .catch((err) => {
          throw err;
        });
    });
  };
};

export const deleteAlert = (alert) => {
  return (dispatch) => {
    axios
      .delete(ALERTS + alert._id)
      .then((response) => {
        dispatch(deleteAlertAction(alert));
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const deleteManyAlerts = (alerts, callback) => {
  const ids = [];
  alerts.map((alert) => ids.push(alert._id));
  return (dispatch) => {
    axios
      .post(DELETE_MANY_ALERTS, { ids: ids })
      .then((response) => {
        callback();
        dispatch(deleteManyAlertAction(ids));
      })
      .catch((err) => {
        callback();
        throw err;
      });
  };
};
