import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import HomePage from "../Home";
import SigninPage from "../SignIn";
import SignupPage from "../Signup";
import Web from "../web";
import AdminPage from "../Admin";
import PrivacyPolicies from '../Privacy_Policies'
import { withFirebase } from "../Firebase";



const LandingPage = (props) => {
  // console.log(props);
  return (
    <div>
      <Switch>
        <Route path={ROUTES.LANDING} component={HomePage} exact />
        <Route path={ROUTES.SIGN_IN} component={SigninPage} exact />
        <Route path={ROUTES.SIGN_UP} component={SignupPage} exact />
        <Route path={ROUTES.WEB} component={Web} exact />
        <Route path={ROUTES.ADMIN} component={AdminPage} exact />
        <Route path={ROUTES.PRIVACY_POLICIES} component={PrivacyPolicies} exact />
      </Switch>
    </div>
  );
};

export default withRouter(withFirebase(LandingPage));
