import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logo } from "../../data";
import { withFirebase } from "../Firebase";

import "./nav.css";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Avatar,
  CssBaseline,
  useScrollTrigger,
  Box,
} from "@material-ui/core";
import { Link } from "react-scroll";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Nav = (props) => {
  const loginClick = () => {
    props.history.push(ROUTES.SIGN_IN);
  };

  const signupClick = () => {
    props.history.push(ROUTES.SIGN_UP);
  };

  const logout = () => {
    props.firebase.doSignOut();
    localStorage.removeItem("authUser");
  };
  const brandClick = () => {
    props.history.push(ROUTES.LANDING);
  };

  const webClick = () => {
    props.history.push(ROUTES.WEB);
  };
  const adminClick = () => {
    props.history.push(ROUTES.ADMIN);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          style={{
            background: "white",
            boxShadow: "initial",
          }}
        >
          <Toolbar>
            <img src={logo} alt="logo" style={{ height: "80px" }} />

            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button>
                <Link
                  style={{ activeClass: "1px solid black" }}
                  activeClass="active"
                  to="marginTop"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Home
                </Link>
              </Button>
              <Button>
                <Link
                  style={{ activeClass: "1px solid black" }}
                  activeClass="active"
                  to="Service"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Our Service
                </Link>
              </Button>

              <Button>
                {" "}
                <Link
                  activeClass="active"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Pricing
                </Link>
              </Button>
              <Button>
                {" "}
                <Link
                  activeClass="active"
                  to="review"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Review
                </Link>
              </Button>
              <Button>
                {" "}
                <Link
                  activeClass="active"
                  to="team"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Teams
                </Link>
              </Button>
              <Button>
                {" "}
                <Link
                  activeClass="active"
                  to="screenshot"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  ScreenShot
                </Link>
              </Button>
              <Button>
                {" "}
                <Link
                  activeClass="active"
                  to="client"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Top Clients
                </Link>
              </Button>
              <Button>
                {" "}
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Contact
                </Link>
              </Button>

              {props.authUser ? (
                <Box display="flex">
                  {props.authUser.is_admin && (
                    <Button onClick={adminClick}>Admin</Button>
                  )}
                  <Button onClick={webClick}>Web</Button>
                  <Button onClick={logout}>Logout</Button>
                  <Avatar
                    src={props.authUser.image}
                    alt={props.authUser.name}
                  />
                </Box>
              ) : (
                <Box display="flex">
                  <Button onClick={loginClick}>Login</Button>
                  <Button onClick={signupClick}>SignUp</Button>
                </Box>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
    // <AppBar position="static" className="navBarPos">
    //   <Toolbar>
    //     <IconButton
    //       edge="start"
    //       className={classes.menuButton}
    //       color="inherit"
    //       aria-label="menu"
    //       onClick={brandClick}
    //     >
    //       <MenuIcon />
    //     </IconButton>
    //     <Typography variant="h6" className={classes.title}>
    //       SultanTracker
    //     </Typography>

    //     {props.authUser ? (
    //       <Grid
    //         container
    //         direction="row"
    //         justify="flex-end"
    //         alignItems="center"
    //       >
    //         <Button color="inherit">
    //           <a className="nav-link" href="/">
    //             Home
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#features-section">
    //             Features
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#screenshot-section">
    //             Screenshoot
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#testimonials-section">
    //             Testimonial
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#download-section">
    //             App Download
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#contact-section">
    //             Contact
    //           </a>
    //         </Button>

    //         {props.authUser.is_admin && (
    //           <Button color="inherit" onClick={adminClick}>
    //             Admin Panel
    //           </Button>
    //         )}

    //         {props.authUser.emailVerified && (
    //           <Button color="inherit" onClick={webClick}>
    //             Web Platform
    //           </Button>
    //         )}

    //         <LogoutButton />
    //         <Avatar alt={props.authUser.name} src={props.authUser.image} />
    //       </Grid>
    //     ) : (
    //       <div>
    //         <Button color="inherit">
    //           <a className="nav-link" href="/">
    //             Home
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#features-section">
    //             Features
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#screenshot-section">
    //             Screenshoot
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#testimonials-section">
    //             Testimonial
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#download-section">
    //             App Download
    //           </a>
    //         </Button>

    //         <Button color="inherit">
    //           <a className="nav-link" href="#contact-section">
    //             Contact
    //           </a>
    //         </Button>
    //         <Button color="inherit" onClick={loginClick}>
    //           Login
    //         </Button>
    //         <Button color="inherit" onClick={signupClick}>
    //           Signup
    //         </Button>
    //       </div>
    //     )}
    //   </Toolbar>
    // </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(withRouter(withFirebase(Nav)));
