import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Menu, ArrowBack, MoreVert } from "@material-ui/icons";

const useStyles = makeStyles((thene) => ({
  typo: {
    marginLeft: 10,
  },
}));

const WebAppBar = ({ menuClick, components, title, backClick }) => {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        {components.length > 1 && (
          <IconButton onClick={backClick}>
            <ArrowBack style={{ color: "#fff" }} />
          </IconButton>
        )}
        <IconButton onClick={menuClick}>
          <Menu style={{ color: "#fff" }} />
        </IconButton>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <Typography className={classes.typo}>{title}</Typography>
          <IconButton>
            <MoreVert style={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default WebAppBar;
