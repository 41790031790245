import React, { useState, useEffect } from "react";
import { Box, Card, IconButton, Typography, TextField, InputAdornment } from '@material-ui/core'
import { grey } from "@material-ui/core/colors";
import { ChevronLeft, ChevronRight, Clear, Search } from "@material-ui/icons";
import { withDevice } from "../device";
import { connect } from "react-redux";
import { fetchAllDevices } from "../../../actions";
import { withFirebase } from "../../Firebase";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';







const DataTable = ({ data }) => {

    

    return (
        <div className="ag-theme-alpine" style={{ flex:1, maxHeight: 430}}>

            <AgGridReact
                rowData={data}>
                <AgGridColumn width={150} headerName="ID" field="id" filter={true}></AgGridColumn>
                <AgGridColumn width={150} field="code"></AgGridColumn>
                <AgGridColumn width={200} headerName="Engine No" field="registration_number"></AgGridColumn>
                <AgGridColumn width={150} headerName="SIM Number" field="device_sim_number"></AgGridColumn>
                <AgGridColumn width={150} headerName="Customer" field="driver_name"></AgGridColumn>
                <AgGridColumn width={150} headerName="Customer-Phone" field="driver_phone"></AgGridColumn>
                <AgGridColumn width={200} headerName="WORKSHOP" field="work_shop"></AgGridColumn>
            
            </AgGridReact>

        </div>
    )

}

const WorkshopReport = ({
  firebase,
  deviceParam,
  fetchAllDevices,
  devices,
}) => {
  const { setTitle } = deviceParam;
  const [search, setSearch] = useState("");
  const [visible] = useState(false);
  const [data,setData] = useState(null);

  useEffect(() => {
    setTitle("Workshop Report");
    if (devices.length === 0) {
      fetchAllDevices(firebase);
    }
    setData([...devices])
  }, [devices]);


  useEffect(()=>{

    if(devices){
        setData([...devices].filter(x=>{

            return(x.id.toLowerCase().includes(search.toLowerCase()) || 
                x.code.toLowerCase().includes(search.toLowerCase()) ||
                x.device_sim_number.toLowerCase().includes(search.toLowerCase()) ||
                x.work_shop.toLowerCase().includes(search.toLowerCase()) ||
                x.driver_name.toLowerCase().includes(search.toLowerCase()) ||
                x.driver_phone.toLowerCase().includes(search.toLowerCase()) 
                )
        }))
    }

    

},[search])



  const handleChange=e=>{
    setSearch(e.target.value);
  }

  return (
    <Box
    display="flex"
    style={{
        width: "100%",
        height: "100%",
        backgroundColor: grey[300],
    }}
  >
        <Card style={{ width: "100%",margin:10 }}>

        <Box
                    display="flex"
                    flexDirection="column"
                    style={{ width: "100%", height: "100%", padding: 10 }}
                >
                    <Box display="flex" justifyContent="space-between" style={{padding:10}}>

                        <Box display="flex" flexDirection="column">
                            <Typography variant="button">No of Vehicles</Typography>
                           {data &&  <Typography variant="body1">{data.length} Nos</Typography>}

                        </Box>
{/* 
                        <Box display="flex" flexDirection="column">
                            <Typography variant="button">Total Day Count</Typography>
                            <Typography variant="body1">{getTotalDayCount()} Days</Typography>

                        </Box> */}

                        {/* <Box display="flex" justifyContent="center" alignItems="center">
                            <IconButton onClick={prevClick}>
                                <ChevronLeft color="secondary" />
                            </IconButton>
                            <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                                Report on {dateformat(date, "mmmm-yyyy")}
                            </Typography>
                            <IconButton onClick={nextClick}>
                                <ChevronRight color="secondary" />
                            </IconButton>
                        </Box> */}

                        <TextField
                            placeholder="Search ......."
                            onChange={handleChange}
                            name="search"
                            value={search}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search
                                            style={{
                                                color: grey[700],
                                                padding: 4,
                                                cursor: "pointer",
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setSearch("")}>
                                            <Clear
                                                style={{
                                                    color: grey[700],
                                                    padding: 4,
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Box>
                    <DataTable data={data} />
                </Box>


        </Card>
  </Box>
  )
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllDevices: (firebase) => dispatch(fetchAllDevices(firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(WorkshopReport)));
