import React, { useState, useEffect } from "react";
import { Card, Typography, IconButton } from "@material-ui/core";
import { withDevice } from "../device";
import { grey } from "@material-ui/core/colors";
import axios from "axios";
import { MONTHLY_LOCATIONS_URL, MONTHLY_FILE_URL } from "../../../utils/url";
import dateformat from "dateformat";
import { GetApp, ChevronLeft, ChevronRight } from "@material-ui/icons";
import UserDeviceAutoComplete from "./daily-report/user_device_autocomplete";
import SharedUserDeviceAutoComplete from "./daily-report/shared_user_devices_autocomplete";
import { MonthlyReportTable } from "./virtual_table";


const MonthlyReport = ({ deviceParam }) => {
  const { setTitle, fromSharedUser } = deviceParam;

  const [state,setState] = useState({
    device:{...deviceParam.device},
    date:new Date(),
    monthlyData:[]
  })

  const prevClick = () => {
    let d = new Date(state.date)
    d.setMonth(d.getMonth()-1)
    setState(old=>({...old,date:d}))
  };

  const nextClick = () => {
    let d = new Date(state.date)
    d.setMonth(d.getMonth()+1)
    setState(old=>({...old,date:d}))
  };

  useEffect(() => {
    setTitle("Report of " + state.device.registration_number);
  }, [state.device]);

  const fetchMonthlyData = () => {
    let data = {
      device_id: state.device.id,
      year: state.date.getFullYear(),
      month: state.date.getMonth(),
      device_type: state.device.vehicle_type,
    };

    axios
      .post(MONTHLY_LOCATIONS_URL, data)
      .then((response) => {
        setState(old=>({...old,monthlyData:response.data}))
      })
      .catch((err) => console.log(err));
  };

  const fuelRequired = (distance, congestionTime) => {
    if (state.device.mileage) {
      let disKm = distance / 1000;
      let runningConsumption = disKm / state.device.mileage;

      if (state.device.congestion_consumption) {
        let x = congestionTime / 3600;
        let congesCon = x * state.device.congestion_consumption;
        runningConsumption = runningConsumption + congesCon;
      }

      return runningConsumption.toFixed(2);
    } else {
      return "undefined";
    }
  };

  const getTotalDistance = () => {

    let d =  state.monthlyData.reduce((acc, obj)=> acc + obj.distance, 0);

    return (d / 1000).toFixed(2);
  };

  const getTotalFuel = () => {
    let totalFuel = state.monthlyData.reduce((acc,x)=>acc+Number(fuelRequired(x.distance,x.congestion_time)),0);
    // console.log(totalFuel,"Fuel")
    if(totalFuel>0){
      return totalFuel.toFixed(2);
    }
    return 0.00;
  };

  const downloadFile = () => {
    let data = {
      device: state.device,
      year: state.date.getFullYear(),
      month: state.date.getMonth(),
    };

    axios
      .post(MONTHLY_FILE_URL, data, { responseType: "blob" })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchMonthlyData();
  }, [state.date,state.device]);

  const setDevice=(device)=>{
    setState(old=>({...old,device:device}))
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <div style={{ width: "100%", marginBottom: 20 }}>
        <Card style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: 10,
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flex: 1,flexDirection:"column" }}>
                         <Typography>Total Distance: {getTotalDistance()} KM</Typography>
               {state.device.mileage && (
                 <Typography>Total Fuel: {getTotalFuel()} Lit</Typography>
               )}
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={prevClick}>
                  <ChevronLeft color="secondary" fontSize="large" />
                </IconButton>
                <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                  Report on {dateformat(state.date, "mmmm-yyyy")}
                </Typography>
                <IconButton onClick={nextClick}>
                  <ChevronRight color="secondary" fontSize="large" />
                </IconButton>
              </div>
              <div
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {fromSharedUser ? (
                  <SharedUserDeviceAutoComplete
                    setDevice={setDevice}
                    device={state.device}
                  />
                ) : (
                  <UserDeviceAutoComplete
                    setDevice={setDevice}
                    device={state.device}
                  />
                )}

                <IconButton onClick={downloadFile}>
                  <GetApp />
                </IconButton>
              </div>
            </div>

            <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
            <div style={{ width: "98%"}}>
                  <MonthlyReportTable monthly_data={state.monthlyData} fuelRequired={fuelRequired} device={state.device}/>
            </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default withDevice(MonthlyReport);
