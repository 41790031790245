import {
  FETCH_SHRED_USERS,
  DELETE_SHRED_DEVICE,
  ADD_SHRED_DEVICE
} from "../utils/types";

const sharedUsersReducer = (state = [], action) => {
  if (action.type === FETCH_SHRED_USERS) {
    return action.payload;
  } else if (action.type === DELETE_SHRED_DEVICE) {
    let sharedDevices = [...state].filter(
      device => device._id !== action.payload._id
    );
    return sharedDevices;
  } else if (action.type === ADD_SHRED_DEVICE) {
    return [...state, action.payload];
  } else {
    return state;
  }
};

export default sharedUsersReducer;
