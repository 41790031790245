import React, { useState, useEffect, useRef } from "react";

import {
  Slide,
  Dialog,
  Card,
  Box,
  Typography,
  Button,
  CircularProgress,
  ListItem,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { withFirebase } from "../../Firebase";
import { withDevice } from "../device";
import axios from "axios";
import { SHARED_USERS, SHARED_DEVICE } from "../../../utils/url";
import { DeleteOutline } from "@material-ui/icons";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SharedUserList = ({ sharedUsers, deleteSharedUsers }) => {
  //console.log(sharedUsers);
  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true,
    })
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowHeight={70}
            rowCount={sharedUsers.length}
            rowRenderer={({ key, index, style, parent }) => {
              const shareUser = sharedUsers[index];
              return (
                <CellMeasurer
                  key={key}
                  cache={cache.current}
                  rowIndex={index}
                  parent={parent}
                >
                  <div style={style}>
                    <ListItem button key={index} divider>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ width: "100%" }}
                      >
                        <Avatar src={shareUser.user.image} />
                        <Box
                          display="flex"
                          flexGrow="1"
                          alignItems="center"
                          style={{ marginLeft: 10 }}
                        >
                          <Box
                            display="flex"
                            flexGrow="1"
                            flexDirection="column"
                          >
                            <Typography variant="body1">
                              {shareUser.user.name}
                            </Typography>
                            <Typography variant="body2">
                              {shareUser.user.contact}
                            </Typography>
                          </Box>

                          <IconButton
                            onClick={() => deleteSharedUsers(shareUser)}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  </div>
                </CellMeasurer>
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

const SharedUsers = ({ firebase, open, setOpen, deviceParam }) => {
  const { device } = deviceParam;
  const [visible, setVisible] = useState(false);
  const [sharedUsers, setSharedUsers] = useState([]);

  useEffect(() => {

    if (device && open) {
      setVisible(true);
      firebase.idToken().then((idToken) => {
        axios
          .get(SHARED_USERS + device.id, {
            headers: { Authorization: "Bearer " + idToken },
          })
          .then((response) => {
            setVisible(false);
            setSharedUsers(response.data);
          })
          .catch((err) => {
            setVisible(false);
            console.log(err);
          });
      });
    }

   
  }, [device, open,firebase]);

  const deleteSharedUsers = (shareUser) => {
    setVisible(true);
    firebase.idToken().then((idToken) => {
      axios
        .delete(SHARED_DEVICE + shareUser._id, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          console.log(response.data);
          setVisible(false);
          setSharedUsers(
            [...sharedUsers].filter((su) => su._id !== shareUser._id)
          );
        })
        .catch((err) => {
          setVisible(false);
        });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Card style={{ width: 500, height: 500, padding: 10 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ width: "100%", height: "100%" }}
        >
          <Box display="flex" flexDirection="column" flexGrow="1">
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">
                Shared User List of {device && device.registration_number}
              </Typography>
            </Box>

            <Box display="flex" flexGrow="1" flexDirection="column">
              <Box display="flex" style={{ width: "100%", height: "100%" }}>
                <SharedUserList
                  sharedUsers={sharedUsers}
                  deleteSharedUsers={deleteSharedUsers}
                />
              </Box>

              {visible && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: "100%", height: "100%", marginTop: "-100%" }}
                >
                  <CircularProgress color="secondary" />
                </Box>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              onClick={() => {
                setOpen(false);
                setSharedUsers([]);
              }}
            >
              Hide
            </Button>
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
};

export default withFirebase(withDevice(SharedUsers));
