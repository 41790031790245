import React from "react";
import {  Card, Typography } from "@material-ui/core";


export const MyCard = ({ title, value }) => {
    return (
      <Card>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="button">{title}</Typography>
          <div style={{width:"100%",height:2,backgroundColor:"#F18905"}}></div>
          <Typography variant="button">{value}</Typography>
        </div>
      </Card>
    );
  };