import React, { useState,useEffect } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Box, Typography } from "@material-ui/core";
import axios from 'axios'
import moment from 'moment'

const plurl =
  "https://locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15";

const ListInfoWindow = ({ infoData,setInfoData }) => {
  const [address, setAddress] = useState("");


  useEffect(()=>{
    let url = plurl + "&lat=" + infoData.geo.lat + "&lon=" + infoData.geo.lng + "&format=json"

    axios.get(url)
      .then(response => {
        if (response.data.display_name) {
          setAddress((old) => response.data.display_name);
        }
      })
      .catch(err => console.log(err))

  },[infoData])

  return (
    <InfoWindow
      position={{ lat: infoData.geo.lat, lng: infoData.geo.lng }}
      onCloseClick={() => setInfoData(null)}
    >
      <Box display="flex" flexDirection="column" style={{ minWidth: 400, maxWidth: 800,padding: 10 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            ID:
          </Typography>
          <Typography>{infoData.id}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Ignition:
          </Typography>
          <Typography>{infoData.geo.acc}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Engine/Registration Number:
          </Typography>
          <Typography>{infoData.registration_number}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: 10 }}
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Current Location:
          </Typography>
          <Typography>{address}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: 10 }}
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Chasis Number:
          </Typography>
          <Typography>{infoData.chasis_number}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: 10 }}
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Code:
          </Typography>
          <Typography>{infoData.code}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: 10 }}
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Speed:
          </Typography>
          <Typography>{infoData.geo.speed} KMPH</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: 10 }}
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Last Update:
          </Typography>

          <Typography>
            {infoData.geo.update_time
              ? moment(infoData.geo.update_time).format("MMMM Do YYYY, h:mm:ss a")
              : "undefined"}
          </Typography>
        </Box>
      </Box>
    </InfoWindow>
  );
};


export default ListInfoWindow
