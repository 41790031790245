import axios from "axios";

import {
  USER_DEVICES,
  ADD_USER_DEVICE,
  UPDATE_USER_DEVICE,
} from "../utils/types";
import { USERS_URL,DEVICES_URL} from "../utils/url";

const getUserDevicesAction = (data) => {
  return { type: USER_DEVICES, payload: data };
};

const addUserDeviceAction = (device) => {
  return { type: ADD_USER_DEVICE, payload: device };
};

const ffff = (oldDevice, newDevice) => {
  //console.log("Update User Device Called in Action");
  return {
    type: UPDATE_USER_DEVICE,
    payload: { oldDevice: oldDevice, newDevice: newDevice },
  };
};

export const addUserDevice = (device) => {
  return (dispatch) => {
    dispatch(addUserDeviceAction(device));
  };
};

export const updateUserDevice = (firebase, device,formData,setShow,setOpen,setFile) => {
  //console.log("Update User Device Called");
  return (dispatch) => {

      setShow(true);

      firebase.idToken().then((idToken) => {
        axios
          .put(DEVICES_URL+"client/" + device.id, formData, {
            headers: { Authorization: "Bearer " + idToken}
          })
          .then((response) => {
            // updateUserDevice(device, response.data);
            setShow(false);
            setOpen(false);
            setFile(null);
            return dispatch(ffff(device, response.data));
          })
          .catch((err) => {
            setShow(false);
            console.log(err);
          });
      });

    
  };
};

export const setUserDevices = (devices) => {
  return (dispatch) => {
    dispatch(getUserDevicesAction(devices));
  };
};

export const getUserDevices = (uid, firebase) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      axios
        .get(USERS_URL + uid+"/devices", {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(getUserDevicesAction(response.data));
        })
        .catch((err) => {
          throw err;
        });
    });
  };
};
