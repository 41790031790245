import React, { useState, useEffect } from "react";
import {Box, Card, Typography,TextField,InputAdornment,IconButton} from '@material-ui/core'
import {Search,Clear} from '@material-ui/icons'
import UnAssignTable from './unassign_table'
import { grey } from "@material-ui/core/colors";
import axios from 'axios'
import {REFERENCE_UNASSIGN_URL} from '../../../utils/url'
import { withDevice } from "../device";
import {connect} from 'react-redux'


const corporateMails = [
    'mtd.logistic1@karnaphuli.com',
    'vts.rangsmotors@gmail.com'
]

const reference = {
    "mtd.logistic1@karnaphuli.com":"Mahindra-Karnaphuli",
    "vts.rangsmotors@gmail.com":"Rangs Motors"
}


const UnAssignDevices=({deviceParam,authUser})=>{
    const { setTitle } = deviceParam;

    const [list,setList] = useState([])
    const [devices,setDevices] = useState([])
    const [search,setSearch] = useState("");

    const is_corporate = corporateMails.includes(authUser.email)

    const getUnAssignDevice=()=>{
        const url = REFERENCE_UNASSIGN_URL+reference[authUser.email]+"/unassign"
        axios.get(url)
            .then(response=>{
                setList(response.data)
            })
    }

    const filterDevice=()=>{

        if(search===""){
            setDevices([...list])
        }else{
            setDevices([...list].filter(device=>{
                if (
                    device.id &&
                    device.registration_number &&
                    device.vehicle_model &&
                    device.device_sim_number &&
                    device.chasis_number &&
                    device.code
                  ) {
                    return (
                      device.id.toLowerCase().includes(search.toLowerCase()) ||
                      device.registration_number
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      device.vehicle_model
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      device.device_sim_number
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      device.code
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      device.chasis_number
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    );
                  }else if( 
                    device.id &&
                    device.registration_number &&
                    device.vehicle_model &&
                    device.device_sim_number &&
                    device.chasis_number
                    ){
                      return (
                        device.id.toLowerCase().includes(search.toLowerCase()) ||
                        device.registration_number
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        device.vehicle_model
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        device.device_sim_number
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        device.chasis_number
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      );
                  }else if( 
                    device.id &&
                    device.registration_number &&
                    device.vehicle_model &&
                    device.device_sim_number &&
                    device.code){
                      return (
                        device.id.toLowerCase().includes(search.toLowerCase()) ||
                        device.registration_number
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        device.vehicle_model
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        device.device_sim_number
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        device.code
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      );
                    }else if( 
                      device.id &&
                      device.registration_number &&
                      device.vehicle_model &&
                      device.device_sim_number){
                        return (
                          device.id.toLowerCase().includes(search.toLowerCase()) ||
                          device.registration_number
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          device.vehicle_model
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          device.device_sim_number
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        );
                      }else if( 
                        device.id &&
                        device.registration_number &&
                        device.device_sim_number){
                          return (
                            device.id.toLowerCase().includes(search.toLowerCase()) ||
                            device.registration_number
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            device.device_sim_number
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          );
                        }else{
                          return (
                            device.id.toLowerCase().includes(search.toLowerCase()) ||
                            device.device_sim_number
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          );
                        }
            }))
        }
    }

    useEffect(()=>{
        setTitle(old=>reference[authUser.email]+" UnAssign Vehicles")
        if(is_corporate){
            
            getUnAssignDevice()
        }
    },[])


    useEffect(filterDevice,[search,list])


    const handleChange=(e)=>{
        setSearch((old) => e.target.value);
    }


    
    
    return(

        is_corporate?(
            <Box style={{padding:10,backgroundColor: grey[300],minHeight:"100%"}}>
                <Card>
                    <Box display="flex" flexDirection="column" >
                        <Box display="flex" justifyContent="space-between" style={{padding:10}}>
                            <Box display="flex" flexDirection="column">
                                <Typography style={{ padding: 2 }}>
                                Total UnAssigned Vehicles: ( {list.length} )
                                </Typography>
                                <Typography style={{ padding: 2 }}>
                                Searched Vehicles: ( {devices.length} )
                                </Typography>
                            </Box>

                            <TextField
                                placeholder="Search"
                                onChange={handleChange}
                                name="search"
                                value={search}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <Search
                                        style={{
                                            color: grey[700],
                                            padding: 4,
                                            cursor: "pointer",
                                        }}
                                        />
                                    </InputAdornment>
                                    ),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setSearch("")}>
                                        <Clear
                                            style={{
                                            color: grey[700],
                                            padding: 4,
                                            cursor: "pointer",
                                            }}
                                        />
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <UnAssignTable  list={devices}/>
                    </Box>
                </Card>
            
            </Box>
           
        ):(
            <Box>You are not a Corporate User ...</Box>
        )
        
        
    )
}

const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };


export default connect(mapStateToProps) (withDevice(UnAssignDevices));