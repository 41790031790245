const deg2rad = (deg) => {
return deg * (Math.PI / 180);
};

const rad2Deg = (rad) => {
    return rad * (180 / Math.PI);
};

const getDistanceFromLatLonInMeter = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 1000; // Distance in km
    return d;
};

const getDiatance = (location1, location2) => {
    return getDistanceFromLatLonInMeter(
        location1.geo.lat,
        location1.geo.lng,
        location2.geo.lat,
        location2.geo.lng
    );
};


const getDistanceFromLocations=locations=>{
    let dist = 0;

    for (let i = 0; i < locations.length; i++) {
      if (i > 0) {
        dist = dist + getDiatance(locations[i - 1], locations[i]);
      }
    }

    dist = (dist / 1000).toFixed(2);
    return dist
}

export{getDiatance,getDistanceFromLocations}