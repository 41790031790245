import React, { useState, useEffect, useRef } from "react";

import { withDevice } from "../web/device";
import { withFirebase } from "../Firebase";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { connect } from "react-redux";
import { fetchAllUsers } from "../../actions";
import { Search, Clear } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import "react-virtualized/styles.css";

const UserList = ({ list, contains, handleCheckChange }) => {
  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true,
    })
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <List
              width={width}
              height={height}
              rowHeight={70}
              rowCount={list.length}
              rowRenderer={({ key, index, style, parent }) => {
                const user = list[index];

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache.current}
                    rowIndex={index}
                    parent={parent}
                  >
                    <div style={style}>
                      <Box
                        display="flex"
                        style={{ padding: 8 }}
                        alignItems="center"
                      >
                        <Checkbox
                          onChange={(e) =>
                            handleCheckChange(e.target.checked, user)
                          }
                          checked={contains(user)}
                        />
                        <Box display="flex" flexDirection="column">
                          <Typography>{user.name}</Typography>
                          <Typography>{user.email}</Typography>
                        </Box>
                      </Box>
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
};

const NotificationUsers = ({
  deviceParam,
  firebase,
  users,
  fetchAllUsers,
  handleCheckChange,
  contains,
}) => {
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([...users]);

  useEffect(() => {
    const userFetch=()=>{
      if (users.length === 0) {
        fetchAllUsers(firebase);
      }
    }

    userFetch()
    
  }, []);

  useEffect(() => {
    filter();
  }, [search]);

  useEffect(() => {
    setFilteredUsers([...users]);
  }, [users]);

  const filter = () => {
    if (search === "") {
      setFilteredUsers([...users]);
    } else {
      setFilteredUsers(
        [...users].filter((user) => {
          if (user.name && user.email) {
            return (
              user.email.toLowerCase().includes(search.toLowerCase()) ||
              user.name.toLowerCase().includes(search.toLowerCase())
            );
          } else {
            return user.email.toLowerCase().includes(search.toLowerCase());
          }
        })
      );
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ padding: 10, width: "100%", height: "100%" }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography>Users ({users.length})</Typography>

        <TextField
          placeholder="Search"
          onChange={handleChange}
          name="search"
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  style={{
                    color: grey[700],
                    padding: 4,
                    cursor: "pointer",
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch("")}>
                  <Clear
                    style={{
                      color: grey[700],
                      padding: 4,
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box display="flex" flexGrow="1" style={{ marginTop: 20 }}>
        <UserList
          list={filteredUsers}
          handleCheckChange={handleCheckChange}
          contains={contains}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllUsers: (firebase) => dispatch(fetchAllUsers(firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(NotificationUsers)));
