import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const MyDatePicker=({selectedDate,setSelectedDate,label})=>{


    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label={label?label:"Select Report Date"}
                value={selectedDate}
                style={{backgroundColor:"#fff",padding:4,borderRadius:4}}
                onChange={(date)=>setSelectedDate(date)}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />

        </MuiPickersUtilsProvider>
        
    )

}

export default MyDatePicker;