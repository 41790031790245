import React, { useEffect, useState } from 'react';

import { Box, Card, Typography, CircularProgress } from '@material-ui/core';
import { CChart, CChartBar } from '@coreui/react-chartjs';
import MyDatePicker from './daily-report/date_picker';
import UserDeviceAutoComplete from './daily-report/user_device_autocomplete';
import SharedUserDeviceAutoComplete from './daily-report/shared_user_devices_autocomplete';

import { DAILY_SPEED_URL } from '../../../utils/url';

import axios from 'axios';

import { withDevice } from '../device';
import { MyCard } from './component-utils';

const DailySpeedReport = ({ deviceParam }) => {
  const { setTitle, fromSharedUser } = deviceParam;

  const [state, setState] = useState({
    selectedDate: new Date(),
    device: { ...deviceParam.device },
    progressVisible: false,
    info: { max: 0, min: 0, avg: 0 },
    data: [],
    frequency: [],
  });

  const minToStr = (timeInMin) => {
    let hour = Math.floor(timeInMin / 60);
    let min = timeInMin % 60;

    let suffix = '';

    let suff = Math.floor(hour / 12);
    hour = hour % 12;

    if (suff == 0) {
      suffix = ' AM';
    } else {
      suffix = ' PM';
    }

    if (hour == 0) {
      hour = 12;
    }

    return (
      String(hour).padStart(2, 0) + ':' + String(min).padStart(2, 0) + suffix
    );
  };

  const setSelectedDate = (date) => {
    setState((oldState) => ({ ...oldState, selectedDate: date }));
  };

  const setDevice = (device) => {
    setState((oldState) => ({ ...oldState, device: device }));
  };

  useEffect(() => {
    setTitle('Daily Speed Report of ' + state.device.registration_number);
  }, [state.device]);

  useEffect(() => {
    if (state.device && state.selectedDate) {
      let postData = {
        id: state.device.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        day: state.selectedDate.getDate(),
        device_type: state.device.vehicle_type,
      };
      setState((old) => ({ ...old, progressVisible: true }));
      axios
        .post(DAILY_SPEED_URL, postData)
        .then((response) => {
          let oldState = { ...state };
          oldState.info = { ...response.data.info };
          oldState.data = [...response.data.data];
          oldState.frequency = [...response.data.frequency];
          setState(oldState);
        })
        .catch((err) => console.log(err));
    }
  }, [state.device, state.selectedDate]);
  return (
    <div
      style={{
        backgroundColor: '#eeeeee',
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MyCard title="Max" value={state.info.max + ' KMPH'} />
        <MyCard title="Avg" value={state.info.avg + ' KMPH'} />
        <MyCard title="Min" value={state.info.min + ' KMPH'} />
        <MyDatePicker
          selectedDate={state.selectedDate}
          setSelectedDate={setSelectedDate}
        />
        {fromSharedUser ? (
          <SharedUserDeviceAutoComplete
            setDevice={setDevice}
            device={state.device}
          />
        ) : (
          <UserDeviceAutoComplete setDevice={setDevice} device={state.device} />
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 30,
          marginTop: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Card style={{ width: '90%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ padding: 10 }} variant="h6">
                  Speed Timeline
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  padding: 10,
                }}
              >
                <CChart
                  style={{ width: '100%' }}
                  type="line"
                  data={{
                    labels: state.data.map((x) => minToStr(x.time)),

                    datasets: [
                      {
                        label: 'Average Speed Timeline',

                        backgroundColor: 'rgba(151, 187, 205,1.0)',

                        borderColor: 'rgba(151, 187, 205, 1)',

                        pointBackgroundColor: 'rgba(151, 187, 205, 1)',

                        pointBorderColor: '#fff',

                        data: state.data.map((x) => x.avg),
                        spanGaps: true,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </Card>
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Card style={{ width: '90%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ padding: 10 }} variant="h6">
                  Speed Frequencies Distribution
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  padding: 10,
                }}
              >
                <CChart
                  style={{ width: '100%' }}
                  type="bar"
                  data={{
                    labels: state.frequency.map(
                      (x) => x._id + ' to ' + (x._id + 10) + ' (K/M)'
                    ),

                    datasets: [
                      {
                        label: 'Speed Frequencies',

                        backgroundColor: '#f87979',

                        data: state.frequency.map((x) => x.count),
                      },
                    ],
                  }}
                  labels="frequency"
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default withDevice(DailySpeedReport);
