import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withDevice } from "../device";

const Notifications = ({ authUser, deviceParam }) => {
  const { setTitle } = deviceParam;

  useEffect(() => {
    setTitle("All Notifications of " + authUser.name);
  }, []);
  return <div>Notifications Page</div>;
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(withDevice(Notifications));
