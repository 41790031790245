import React, { useState } from "react";

import { withDevice } from "../web/device";
import {
  Slide,
  Dialog,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { deleteDevice } from "../../actions";
import { withFirebase } from "../Firebase";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDevice = ({
  deviceParam,
  open,
  setOpen,
  firebase,
  deleteDevice,
}) => {
  const { device, setDevice } = deviceParam;
  const [show, setShow] = useState(false);

  const resolve = () => {
    setDevice(null);
    setShow(false);
    setOpen(false);
  };

  const deleteClick = (e) => {
    setShow(true);
    deleteDevice(device, firebase, resolve);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Box display="flex" flexDirection="column" style={{ width: 500 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Typography variant="h5">Delete Device??</Typography>
          <Typography variant="button" style={{ marginTop: 10 }}>
            Do you really want to delete {device && device.id}
          </Typography>
        </Box>

        {show && (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="secondary" />
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" style={{ padding: 10 }}>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={deleteClick}>
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteDevice: (device, firebase, resolve) =>
      dispatch(deleteDevice(device, firebase, resolve)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDevice(withFirebase(DeleteDevice)));
