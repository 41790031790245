import React from 'react'
import {connect} from 'react-redux'
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';


const UserDeviceAutoComplete = ({userDevices,setDevice,device})=>{
    
    return(
        <Autocomplete
            id="combo-box-demo"
            options={[...userDevices].filter(dev=>dev.code && dev.code!=="")}
            getOptionLabel={(device) => device.code}
            style={{ width: 300 }}
            value={device}
            onChange={(event,value,reason)=>value && setDevice(value)}
            renderInput={(params) => <TextField {...params} label="Select Code" variant="outlined" style={{backgroundColor:"#fff"}} />}
        />
    )


}


const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };

  export default connect(
    mapStateToProps
  ) (UserDeviceAutoComplete)