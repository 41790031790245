import React from "react";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MySnackBar = ({ snacState, setSnacState }) => {
  return (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snacState.open}
      onClose={() =>
        setSnacState((old) => {
          const st = { ...old };
          st.open = !old.open;
          return st;
        })
      }
    >
      <Alert
        onClose={() =>
          setSnacState((old) => {
            const st = { ...old };
            st.open = !old.open;
            return st;
          })
        }
        severity={snacState.severity}
      >
        {snacState.message}
      </Alert>
    </Snackbar>
  );
};

export default MySnackBar;
