import React from "react";

const DeviceContext = React.createContext(null);

export const withDevice = (Component) => (props) => (
  <DeviceContext.Consumer>
    {(deviceParam) => (
      <Component {...props} deviceParam={deviceParam}></Component>
    )}
  </DeviceContext.Consumer>
);

export default DeviceContext;
